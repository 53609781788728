import React, {useState, useEffect, useMemo} from 'react'
import styled from "styled-components";
import bg from './img/bg.png'
import {MainLayout} from './styles/Layouts'
import Orb from './Components/Orb/Orb'
import Navigation from './Views/Bestätiger/Navigation'
import Home from './Views/Admin/Home';
import Termine from './Views/Bestätiger/Termine'
import { useGlobalContext } from './context/globalContext';
import { MdMenu } from 'react-icons/md';

function App() {
  const [active, setActive] = useState(1)
  const [isNavVisible, setIsNavVisible] = useState(true); 

  const toggleNav = () => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    if (mediaQuery.matches) {
      setIsNavVisible(!isNavVisible); // Umschalten der Sichtbarkeit nur auf mobilen Geräten
    }
  };

  const closeNav = () => {
    setIsNavVisible(false); // Funktion zum Schließen der Navigation
  };

   // Setzt isNavVisible auf false, wenn die Bildschirmbreite 768px oder weniger beträgt
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    if (mediaQuery.matches) {
      setIsNavVisible(false);
    }
  }, []);

  const global = useGlobalContext()
  console.log(global);

  const displayData = () => {
    switch(active){
      case 3:
        return <Termine />
      default: 
        return <Home />
    }
  }

  const orbMemo = useMemo(() => {
    return <Orb />
  },[])

  return (
    <AppStyled bg={bg} className="App">
      {orbMemo}
      <MainLayout>
      <NavigationWrapper isNavVisible={isNavVisible}>
          {isNavVisible && <Navigation active={active} setActive={setActive} closeNav={closeNav} />}
        </NavigationWrapper>
        <NavIcon onClick={toggleNav}>☰</NavIcon> {/* Navigationsicon */}
        <MainContent isNavVisible={isNavVisible}>
          {displayData()}
        </MainContent>
      </MainLayout>
    </AppStyled>
  );
}

const AppStyled = styled.div`
  height: 100vh;
  width: 100vw; // Setzt die Breite auf die Breite des Viewports
  overflow-x: hidden; // Verhindert horizontales Scrollen
  background-image: url(${props => props.bg});
  position: relative;
  main{
    flex: 1;
    background: rgba(252, 246, 249, 0.78);
    border: 3px solid #FFFFFF;
    backdrop-filter: blur(4.5px);
    border-radius: 32px;
    overflow-x: hidden;
    &::-webkit-scrollbar{
      width: 0;
    }
  }

  @media (max-width: 768px) {
    main {
      margin-left: 0; // Entfernt den Abstand, wenn die Navigation nicht sichtbar ist
    }
  }
`;

const NavIcon = styled(MdMenu)`
  display: none; // Standardmäßig nicht angezeigt

  @media (max-width: 768px) {
    display: block; // Auf mobilen Geräten angezeigt
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    font-size: 45px; // Größe des Icons
    z-index: 30; // Stellen Sie sicher, dass das Icon über der Navigation liegt
    padding: 2px; // Platz um das Icon herum für die Box
    font-size: 35px;
    background-color: white; // Hintergrundfarbe der Box
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Schatten für die Boxx
    border-radius: 0 0 10px 0;
  }
`;

const MainContent = styled.main`
  flex: 1;
  background: rgba(252, 246, 249, 0.78);
  border: 3px solid #FFFFFF;
  backdrop-filter: blur(4.5px);
  border-radius: 32px;
  z-index: 10;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width: 0;
  }

  ${({ isNavVisible }) => isNavVisible && `
    @media (max-width: 768px) {
      display: none; // Versteckt das <main>-Element, wenn die Navigation sichtbar ist
    }
  `}
`;

const NavigationWrapper = styled.div`
  ${({ isNavVisible }) => isNavVisible ? `
    animation: slideIn 0.3s forwards;
  ` : `
    animation: slideOut 0.3s forwards;
  `}

  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%; // oder die gewünschte Breite der Navigation
    height: 100%;
    background: white; // oder die gewünschte Hintergrundfarbe
    z-index: 20;
  }
`;

export default App;
