import React, { useState, useEffect } from 'react';
import { useGlobalContext } from './context/globalContext';
import bg from './img/bg.png';
import logo from './img/logoSWB.svg';
import styled, { keyframes } from 'styled-components';
import { eye_open, eye_close } from './utils/Icons';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease forwards;
`;

const Button = styled.button`
  background-color: ${({ isReady }) => (isReady ? '#007bff' : '#ccc')};
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: ${({ isReady }) => (isReady ? 'pointer' : 'default')};
  margin: 0 auto; /* Horizontal zentriert */
  display: block; /* Damit die margin-Eigenschaft funktioniert */
  transition: background-color 0.3s;

  &:hover {
    ${({ isReady }) => isReady && 'transform: scale(1.05);'}
  }
  `;

const RoundedBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60vh;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50px 0px 0 0;
  z-index: -1;
  transition: transform 0.5s ease;
  box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.5);
  transform: translateY(100%);
`;

const StyledButton = styled.button`
  font-size: 25px;
  font-family: Verdana, sans-serif;
  color: white;
  display: block;
  background-image: linear-gradient(
    to right,
    #214fc4 0%,
    #1da7fd 51%,
    #214fc4 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  height: 60px;
  width: 200px;
  margin: 0 auto;
  border-radius: 2em;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-position: right center;
  }
`;

const PasswordContainer = styled.div`
  position: relative;
  width: 100%;
`;

const EyeIcon = styled.span`
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { handleLogin } = useGlobalContext();
  const [roundedBarTransform, setRoundedBarTransform] = useState('translateY(100%)');
  const [isVisible, setIsVisible] = useState(false);
  const [containerVisible, setContainerVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const handleSubmit = async () => {
    try {
      await handleLogin(email, password);
    } catch (error) {
      setError(error.message);
    }
  };
  
  const handlePasswordKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    // Check if both email and password are filled to enable button
    setIsReady(email.trim() !== '' && password.trim() !== '');
  }, [email, password]);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setRoundedBarTransform('translateY(0)');
      }, 100);
    }
  }, [isVisible]);

  const handleButtonClick = () => {
    setIsVisible(true);
    setTimeout(() => {
      setContainerVisible(true);
    }, 350); // Delay for container visibility
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={styles.background}>
      <div style={styles.logoContainer}>
        <img src={logo} alt="Logo" style={styles.logo} />
      </div>
      {containerVisible && (
        <Container>
          <h1 style={styles.heading}>Anmelden</h1>
          <StyledInput
            type="email"
            placeholder="E-Mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handlePasswordKeyPress}
          />
          <PasswordContainer>
            <StyledInput
              type={showPassword ? 'text' : 'password'}
              placeholder="Passwort"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handlePasswordKeyPress}
            />
            <EyeIcon onClick={togglePasswordVisibility}>
              {showPassword ? eye_open : eye_close}
            </EyeIcon>
          </PasswordContainer>
          {error && <p style={styles.error}>{error}</p>}
          <Button isReady={isReady} onClick={handleSubmit}>Anmelden</Button>
        </Container>
      )}
      {!isVisible && (
        <StyledButton onClick={handleButtonClick}>Login</StyledButton>
      )}
      <RoundedBar style={{ transform: roundedBarTransform }} />
    </div>
  );
};

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;

  &:focus,
  &:hover {
    border-color: #1e81b0;
    border: 2px solid #1e81b0;
    outline: none;
  }
`;

const styles = {
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: -1,
  },
  logoContainer: {
    textAlign: 'center',
    marginTop: '20rem',
    padding: '20px',
  },
  logo: {
    width: '100%',
    height: 'auto',
    maxWidth: '700px',
    margin: '0 auto',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  error: {
    color: 'red',
    fontSize: '15px',
    textAlign: 'center',
    marginBottom: '10px',
  },
};

export default Login;
