import React from 'react';
import { Page, Text, Document, StyleSheet, View, Image } from '@react-pdf/renderer';

// Importiere dein Logo als Bild
import swblogo from '../../img/swblogo.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#f7f7f7',
  },
  section: {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20, // Erhöhe den oberen Abstand
  },
  adresse: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  slogan: {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
    fontSize: 16,
    fontWeight: 'bold',
  },
  title: {
    fontSize: 24,
    marginTop: 20,
    marginBottom: 15,
    color: '#1f8dcd',
  },
  boxContainer: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'stretch', // Änderung hier, um die Boxen weiter auseinander zu platzieren
    width: '90%', // Änderung hier, um den Abstand zwischen den Boxen zu vergrößern
    gap: 30,
  },
  box: {
    width: '30%',
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center', // Zentriert den Text innerhalb der Box
  },
  boxTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
    textAlign: 'center', // Zentriert den Text
    color: '#f8c713', // Farbe hinzugefügt
  },
  boxText: {
    fontSize: 12,
    textAlign: 'center', // Zentriert den Text
  },
  contactInfo: {
    marginTop: 40,
    fontSize: 18,
    fontWeight: 'bold',
  },
  terminInfo: {
    marginTop: 40,
    marginBottom: 10,
    fontSize: 18,
    fontWeight: 'bold',
  },
  ContactInfoDetails: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'stretch',
    gap: 45
  },
  kommentar_box: {
    width: 190,
    height: 100,
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
  kommentar_verkäufer_box: {
    width: 500,
    height: 50,
    backgroundColor: 'white',
    borderRadius: 10,
    overflow: 'hidden',
    wordWrap: 'break-word',
  },
  fatsmalltext: {
    marginBottom: 4,
    fontSize: 14,
  },
  thinsmalltext: {
    marginBottom: 20,
    fontSize: 18,
    fontWeight: 'bold',
  },
  kommentar_Text: {
    fontSize: 8,
  },
  termininfotextContainer: {
    backgroundColor: 'white',
    borderRadius: 10, // Anpassen der Rundung der Ecken nach Bedarf
    width: 250,
    height: 20,
    alignItems: 'center', // Zentriert den Text horizontal im Container
    justifyContent: 'center',
    marginBottom: 5,
  },
  termininfotextLONGContainer: {
    backgroundColor: 'white',
    borderRadius: 10, // Anpassen der Rundung der Ecken nach Bedarf
    width: 500,
    height: 20,
    alignItems: 'center', // Zentriert den Text horizontal im Container
    justifyContent: 'center',
    marginBottom: 5,
  },
  TerminInfoDetails: {
    flexDirection: 'row',
    justifyContent: 'stretch',
    gap: 45,
  },
  LeftTerminInfo: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  RightTerminInfo: {
    flexDirection: 'column',
    alignItems: 'center',
  }

});

const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const months = [
    'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
    'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
  ];
  return `${date.getDate()}. ${months[date.getMonth()]} ${date.getFullYear()}`;
};

// Create Document Component
export const TerminPDF = ({ jahresverbrauch, personen_count, dacheindeckung, mtl_abschlag, art_flaeche, ausrichtung_flaeche, phone_1, phone_2, email, vorname, nachname, plz, ort, strasse, hausnummer, uhrzeit, datum, kommentar, werber, status }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        {/*<Image src={swblogo} style={styles.swblogo} />*/}
        <Text style={styles.title}>Beratungstermin für Solarstrom</Text>
        <Text>für:</Text>
        <Text style={styles.adresse}>{plz}, {ort}, {strasse} {hausnummer}</Text>
        <Text style={styles.slogan}>Ein qualifizierter Beratungstermin übermittelt durch SWB-Solar GmbH</Text>
        <View style={styles.boxContainer}>
          <View style={styles.box}>
              <Text style={styles.boxTitle}>Status</Text>
              <Text style={styles.boxText}>{status}</Text>
          </View>
          <View style={styles.box}>
            <Text style={styles.boxTitle}>Datum</Text>
            <Text style={styles.boxText}>{formatDate(datum)}</Text>
          </View>
          <View style={styles.box}>
            <Text style={styles.boxTitle}>Uhrzeit</Text>
            <Text style={styles.boxText}>{uhrzeit}</Text>
          </View>
        </View>

        <View>
          <Text style={styles.contactInfo}>Kontaktinformationen</Text>
        </View>

        <View style={styles.ContactInfoDetails}>
        <View style={styles.LeftContactInfo}>
        {vorname ? (
          <>
            <Text style={styles.fatsmalltext}>Kundenname:</Text>
            <Text style={styles.thinsmalltext}>{vorname} {nachname}</Text>
          </>
        ) : (
          <>
            <Text style={styles.fatsmalltext}>Kundenname:</Text>
            <Text style={styles.thinsmalltext}>k.A.</Text>
          </>
        )}
        {email ? (
          <>
            <Text style={styles.fatsmalltext}>Email:</Text>
            <Text style={styles.thinsmalltext}>{email}</Text>
          </>
        ) : (
          <>
            <Text style={styles.fatsmalltext}>Email:</Text>
            <Text style={styles.thinsmalltext}>k.A.</Text>
          </>
        )}
        </View>
        <View style={styles.CentralContactInfo}>
        {phone_1 ? (
          <>
            <Text style={styles.fatsmalltext}>Telefonnummer 1:</Text>
            <Text style={styles.thinsmalltext}>{phone_1}</Text>
          </>
        ) : (
          <>
            <Text style={styles.fatsmalltext}>Telefonnummer 1:</Text>
            <Text style={styles.thinsmalltext}>k.A.</Text>
          </>
        )}
        {phone_2 ? (
          <>
            <Text style={styles.fatsmalltext}>Telefonnummer 2:</Text>
            <Text style={styles.thinsmalltext}>{phone_2}</Text>
          </>
        ) : (
          <>
            <Text style={styles.fatsmalltext}>Telefonnummer 2:</Text>
            <Text style={styles.thinsmalltext}>k.A.</Text>
          </>
        )}
        </View>
        <View style={styles.RightContactInfo}>
        <Text style={styles.fatsmalltext}>Kommentar Werber:</Text>
        <View style={styles.kommentar_box}>
            <Text style={styles.kommentar_Text}>{kommentar}</Text>
          </View>
        </View>
        </View>

        <View>
          <Text style={styles.terminInfo}>Termininformationen</Text>
        </View>
        <View style={styles.TerminInfoDetails}>
        <View style={styles.LeftTerminInfo}>
        {jahresverbrauch ? (
          <>
            <Text style={styles.fatsmalltext}>Jahresverbrauch:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>{jahresverbrauch}</Text>
            </View>
          </>
        ) : (
          <>
            <Text style={styles.fatsmalltext}>Jahresverbrauch:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>k.A.</Text>
            </View>
          </>
        )}
        {personen_count ? (
          <>
            <Text style={styles.fatsmalltext}>Personen im Haushalt:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>{personen_count}</Text>
            </View>
          </>
        ) : (
          <>
            <Text style={styles.fatsmalltext}>Personen im Haushalt:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>k.A.</Text>
            </View>
          </>
        )}
        {mtl_abschlag ? (
          <>
            <Text style={styles.fatsmalltext}>Mtl. Abschlag €:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>{mtl_abschlag}</Text>
            </View>
          </>
        ) : (
          <>
            <Text style={styles.fatsmalltext}>Mtl. Abschlag €:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>k.A.</Text>
            </View>
          </>
        )}
        </View>
        <View style={styles.RightTerminInfo}>
        {dacheindeckung ? (
          <>
            <Text style={styles.fatsmalltext}>Dacheindeckung:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>{dacheindeckung}</Text>
            </View>
          </>
        ) : (
          <>
            <Text style={styles.fatsmalltext}>Dacheindeckung:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>k.A.</Text>
            </View>
          </>
        )}
        {art_flaeche ? (
          <>
            <Text style={styles.fatsmalltext}>Art der Fläche:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>{art_flaeche}</Text>
            </View>
          </>
        ) : (
          <>
            <Text style={styles.fatsmalltext}>Art der Fläche:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>k.A.</Text>
            </View>
          </>
        )}
        {ausrichtung_flaeche ? (
          <>
            <Text style={styles.fatsmalltext}>Ausrichtung der Fläche:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>{ausrichtung_flaeche}</Text>
            </View>
          </>
        ) : (
          <>
            <Text style={styles.fatsmalltext}>Ausrichtung der Fläche:</Text>
            <View style={styles.termininfotextContainer}>
              <Text style={styles.thinsmalltext}>k.A.</Text>
            </View>
          </>
        )}
        </View>
        </View>

        <View>
          <Text style={styles.terminInfo}>Kommentar Verkäufer</Text>
        </View>
        <View style={styles.kommentar_verkäufer_box}>
            <Text style={styles.kommentar_Text}>{kommentar}</Text>
          </View>


      </View>
    </Page>
  </Document>
);
