import styled from "styled-components";

export const MainLayout = styled.div`
    padding: 2rem;
    height: 100%;
    display: flex;
    gap: 2rem;
    @media (max-width: 768px) {
        padding: 1rem;
    }
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1366px) 
    and (orientation: landscape) {
        padding: 1rem;
    }
`;

export const InnerLayout = styled.div`
    padding: 2rem 1.5rem;
    width: 100%;
    @media (max-width: 768px) {
        padding: 1rem;
    }
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1366px) 
    and (orientation: landscape) {
        padding: 1rem;
    }
`;