import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useGlobalContext } from '../../context/globalContext';
import Button from '../../Components/Button/Button';
import CustomTimePicker from '../../Components/CustomTimePicker/CustomTimePicker'
import Swal from 'sweetalert2'

function Form({ latestEntryStrasse }) {
    const { addTermin, error, setError, getLatestEntryStrasse, getAllStrassen, strassenList } = useGlobalContext();
    const [inputState, setInputState] = useState({
        jahresverbrauch: '',
        personen_count: '',
        mtl_abschlag: '',
        art_flaeche: '',
        dacheindeckung: '',
        ausrichtung_flaeche: '',
        strasse: '',
        plz: '',
        ort : '',
        vorname: '',
        nachname: '',
        email: '',
        phone_1: '',
        phone_2: '',
        hausnummer: '',
        datum: '',
        uhrzeit: '',
        status: '',
        kommentar: '',
        werber: JSON.parse(localStorage.getItem('benutzer')) || '',
    });

    const { jahresverbrauch, personen_count, mtl_abschlag, art_flaeche, dacheindeckung, ausrichtung_flaeche, strasse, plz, ort, vorname, nachname, email, phone_1, phone_2, hausnummer, datum, uhrzeit, status, kommentar, werber } = inputState;

    const handleInput = name => e => {
        setInputState({ ...inputState, [name]: e.target.value });
        setError('');
    };

    // Felder die geleert werden bei statuswechsel
    const clearFields = () => {
        setInputState(prevState => ({
            ...prevState,
            jahresverbrauch: '',
            personen_count: '',
            mtl_abschlag: '',
            art_flaeche: '',
            dacheindeckung: '',
            ausrichtung_flaeche: '',
            vorname: '',
            nachname: '',
            email: '',
            phone_1: '',
            phone_2: '',
            datum: '',
            uhrzeit: '',
            kommentar: '',
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        await addTermin(inputState);
        await getLatestEntryStrasse(werber);
        setInputState({
            ...inputState,
            jahresverbrauch: '',
            personen_count: '',
            mtl_abschlag: '',
            art_flaeche: '',
            dacheindeckung: '',
            ausrichtung_flaeche: '',
            strasse: '',
            plz: '',
            ort: '',
            vorname: '',
            nachname: '',
            email: '',
            phone_1: '',
            phone_2: '',
            hausnummer: '',
            datum: '',
            uhrzeit: '',
            status: '',
            kommentar: '',
            werber: '',
        });
        Swal.fire({
            position: "center",
            icon: "success",
            title: "Eintrag gespeichert!",
            background: "#fcf6f9",
            color: "#222260",
            showConfirmButton: false,
            timer: 1500
          });
          
    };

    // Wenn status sich ändert, cleare alle Felder unter Status
    useEffect(() => {
        if (status !== '') {
            clearFields();
        }
        const allStrassen = getAllStrassen()
        console.log("strassenList", strassenList)
    }, [status]);

    const formatAddress = (strasse, hausnummer, plz, ort) => {
        return `${plz},${ort},${strasse},${hausnummer}`;
    };

    const isAddressVisited = (address) => {
        return strassenList.includes(address);
    };

    const [addressVisited, setAddressVisited] = useState(false);

    useEffect(() => {
        const currentAddress = formatAddress(strasse, hausnummer, plz, ort);
        if (isAddressVisited(currentAddress)) {
            console.log("Adresse bereits besucht!!");
            setAddressVisited(true);
        } else {
            setAddressVisited(false);
        }
    }, [strasse, hausnummer, plz, ort]);


    useEffect(() => {
    // Aktualisiere das Straßenfeld im Formular mit den neuesten Daten
    if (latestEntryStrasse.length != 0 && latestEntryStrasse) {
        console.log("DASSSS", latestEntryStrasse)
        setInputState(prevState => ({
            ...prevState,
            strasse: latestEntryStrasse.latestEntry.strasse || '',
            plz: latestEntryStrasse.latestEntry.plz || '', // Optional chaining hier verwendet
            ort: latestEntryStrasse.latestEntry.ort || '',
            werber: JSON.parse(localStorage.getItem('benutzer')) || '',
        }));
    }
}, [latestEntryStrasse]);

    const buttonName = () => {
        switch (status) {
            case 'Termin':
                return 'Termin speichern';
            case 'Wiedervorlage':
                return 'Wiedervorlage speichern';
            case 'Keine Interesse':
                return 'Fertig';
            case 'Nicht Da':
                return 'Fertig';
            default:
                return 'speichern';
        }
    };

    const [hourValid, setHourValid] = useState(false);

    const handleHourValidChange = (valid) => {
        setHourValid(valid);
    };

    // Funktion zum Überprüfen der Bedingungen für das Erscheinen des Buttons
    const shouldShowButton = () => {
        if (status === 'Termin') {
            return strasseValid && hausnummerValid && PLZValid && OrtValid && vornameValid && nachnameValid && emailValid && phone_1Valid && phone_2Valid && jahresverbrauchValid && personen_count.trim() !== '' && mtl_abschlagValid && datum !== '' && uhrzeit !== '' && hourValid; // Button anzeigen, wenn alle Felder valide
        } else if (status === 'Wiedervorlage') {
            return nachnameValid && strasseValid && hausnummerValid && PLZValid && OrtValid && WiedervorlagevornameValid && WiedervorlagenachnameValid && WiedervorlageemailValid && Wiedervorlagephone_1Valid && Wiedervorlagephone_2Valid && datum !== '' && uhrzeit !== '' && hourValid;
        } else if (status === 'Nicht Da') {
            return strasseValid && hausnummerValid && PLZValid && OrtValid;
        } else if (status === 'Keine Interesse') {
            return strasseValid && hausnummerValid && PLZValid && OrtValid;
        }
        return false;
    };

    const [timezeit, setTimezeit] = useState('00:00');

    const handleTimeChange = (time) => {
        setTimezeit(time);
        setInputState({ ...inputState, uhrzeit: time }); // Hier wird die Uhrzeit im inputState aktualisiert
    };

    // Validierung für Straße
    const [strasseValid, setStrasseValid] = useState(true);
    const [strasseError, setStrasseError] = useState('');
    const [strasseTouched, setstrasseTouched] = useState(false);

    const handleStrasseChange = (e) => {
        const newStrasse = e.target.value;
        setInputState({ ...inputState, strasse: newStrasse });
        validateStrasse(newStrasse);
    };

    const validateStrasse = (newStrasse) => {
        if (newStrasse === '') {
            setStrasseValid(false);
            setStrasseError('Straße darf nicht leer sein.');
        } else if (/\d/.test(newStrasse)) {
            setStrasseValid(false);
            setStrasseError('Straße darf keine Zahlen enthalten.');
        } else {
            setStrasseValid(true);
            setStrasseError('');
        }
    };

    const handleStrasseBlur = () => {
        setstrasseTouched(true)
        validateStrasse(inputState.strasse);
    };

    // Validierung für Ort
    const [OrtValid, setOrtValid] = useState(true);
    const [OrtError, setOrtError] = useState('');
    const [OrtTouched, setOrtTouched] = useState(false);

    const handleOrtChange = (e) => {
        const newOrt = e.target.value;
        setInputState({ ...inputState, ort: newOrt });
        validateOrt(newOrt);
    };

    const validateOrt = (newOrt) => {
        if (newOrt === '') {
            setOrtValid(false);
            setOrtError('Ort darf nicht leer sein.');
        } else if (/\d/.test(newOrt)) {
            setOrtValid(false);
            setOrtError('Ort darf keine Zahlen enthalten.');
        } else {
            setOrtValid(true);
            setOrtError('');
        }
    };

    const handleOrtBlur = () => {
        setOrtTouched(true)
        validateOrt(inputState.ort);
    };

    // Validierung für PLZ
    const [PLZValid, setPLZValid] = useState(true);
    const [PLZError, setPLZError] = useState('');
    const [PLZTouched, setPLZTouched] = useState(false);

    const handlePLZChange = (e) => {
        const newPLZ = e.target.value;
        setInputState({ ...inputState, plz: newPLZ });
        validatePLZ(newPLZ);
    };

    const validatePLZ = (newPLZ) => {
        if (newPLZ === '') {
            setPLZValid(false);
            setPLZError('PLZ darf nicht leer sein.');
        } else if (!/^\d+$/.test(newPLZ)) {
            setPLZValid(false);
            setPLZError('PLZ darf nur Zahlen enthalten.');
        } else {
            setPLZValid(true);
            setPLZError('');
        }
    };

    const handlePLZBlur = () => {
        setPLZTouched(true)
        validatePLZ(inputState.plz);
    };

    // Validierung für Hausnummer
    const [hausnummerValid, setHausnummerValid] = useState(false);
    const [hausnummerError, setHausnummerError] = useState('');
    const [hausnummerTouched, setHausnummerTouched] = useState(false);

    const handleHausnummerChange = (e) => {
        const newHausnummer = e.target.value;
        setInputState({ ...inputState, hausnummer: newHausnummer });
        validateHausnummer(newHausnummer);
    };

    const validateHausnummer = (newHausnummer) => {
        if (newHausnummer === '') {
            setHausnummerValid(false);
            setHausnummerError('Hausnummer darf nicht leer sein.');
        } else if (!/^\d.*$/.test(newHausnummer)) {
            setHausnummerValid(false);
            setHausnummerError('Hausnummer muss mit einer Zahl beginnen.');
        } else {
            setHausnummerValid(true);
            setHausnummerError('');
        }
    };

    const handleHausnummerBlur = () => {
        setHausnummerTouched(true)
        validateHausnummer(inputState.hausnummer);
    };

    const [vornameValid, setVornameValid] = useState(false); // Setze vornameValid von Anfang an auf false
    const [vornameError, setVornameError] = useState('');
    const [WiedervorlagevornameValid, setWiedervorlagevornameValid] = useState(true);
    const [vornameTouched, setVornameTouched] = useState(false);

    const handleVornameChange = (e) => {
        const newVorname = e.target.value;
        setInputState({ ...inputState, vorname: newVorname });
        validateVorname(newVorname, status);
    };

    const validateVorname = (newVorname, status) => {
        if (status === 'Termin') {
            if (newVorname === '') {
                setVornameValid(false);
                setVornameError('Vorname darf nicht leer sein.');
            } else if (/\d/.test(newVorname)) {
                setVornameValid(false);
                setVornameError('Vorname darf keine Zahlen enthalten.');
            } else {
                setVornameValid(true);
                setVornameError('');
            }
        } else if (status === 'Wiedervorlage') {
            if (newVorname === '' || !/\d/.test(newVorname)) {
                setWiedervorlagevornameValid(true);
                setVornameError('');
            } else {
                setWiedervorlagevornameValid(false);
                setVornameError('Ungültiger Vorname');
            }
        }
    };

    const handleVornameBlur = () => {
        setVornameTouched(true);
        validateVorname(inputState.vorname, status);
    };
    
    // Validierung für Nachname
    const [nachnameValid, setNachnameValid] = useState(false);
    const [nachnameError, setNachnameError] = useState('');
    const [WiedervorlagenachnameValid, setWiedervorlagenachnameValid] = useState(true);
    const [nachnameTouched, setNachnameTouched] = useState(false);

    const handleNachnameChange = (e) => {
        const newNachname = e.target.value;
        setInputState({ ...inputState, nachname: newNachname });
        validateNachname(newNachname, status);
    };

    const validateNachname = (newNachname, status) => {
        if (status === 'Termin') {
            if (newNachname === '') {
                setNachnameValid(false);
                setNachnameError('Nachname darf nicht leer sein.');
            } else if (/\d/.test(newNachname)) {
                setNachnameValid(false);
                setNachnameError('Nachname darf keine Zahlen enthalten.');
            } else {
                setNachnameValid(true);
                setNachnameError('');
            }
        } else if (status === 'Wiedervorlage') {
            if (newNachname === '') {
                setNachnameValid(false);
                setNachnameError('Nachname darf nicht leer sein.');
            } else if (/\d/.test(newNachname)) {
                setNachnameValid(false);
                setNachnameError('Nachname darf keine Zahlen enthalten.');
            } else {
                setNachnameValid(true);
                setNachnameError('');
            }
        }
    };

    const handleNachnameBlur = () => {
        setNachnameTouched(true)
        validateNachname(inputState.nachname, status);
    };

    // Validierung für Email
    const [emailValid, setEmailValid] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [WiedervorlageemailValid, setWiedervorlageemailValid] = useState(true);
    const [emailTouched, setEmailTouched] = useState(false);

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setInputState({ ...inputState, email: newEmail });
        validateEmail(newEmail, status);
    };




    const validateEmail = (newEmail, status) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (status === 'Termin') {
            if (newEmail === '') {
                setEmailValid(false);
                setEmailError('E-Mail-Adresse darf nicht leer sein.');
            } else if (!emailRegex.test(newEmail)) {
                setEmailValid(false);
                setEmailError('Ungültige E-Mail-Adresse.');
            } else {
                setEmailValid(true);
                setEmailError('');
            }
        } else if (status === 'Wiedervorlage') {
            if (newEmail === '') {
                setWiedervorlageemailValid(true);
                setEmailError('');
            } else if (!emailRegex.test(newEmail)) {
                setWiedervorlageemailValid(false);
                setEmailError('Ungültige E-Mail-Adresse.');
            } else {
                setWiedervorlageemailValid(true);
                setEmailError('');
            }
        }
    };

    const handleEmailBlur = () => {
        setEmailTouched(true)
        validateEmail(inputState.email, status);
    };

    // Validierung für phone_1
    const [phone_1Valid, setPhone_1Valid] = useState(false);
    const [phone_1Error, setPhone_1Error] = useState('');
    const [Wiedervorlagephone_1Valid, setWiedervorlagephone_1Valid] = useState(true);
    const [phone_1Touched, setPhone_1Touched] = useState(false);

    const handlePhone_1Change = (e) => {
        const newPhone_1 = e.target.value;
        setInputState({ ...inputState, phone_1: newPhone_1 });
        validatePhone_1(newPhone_1, status);
    };


    const validatePhone_1 = (newPhone_1, status) => {
        if (status === 'Termin') {
            if (newPhone_1 === '') {
                setPhone_1Valid(false);
                setPhone_1Error('Telefonnummer darf nicht leer sein.');
            } else if (!/^[^a-zA-Z]+$/.test(newPhone_1)) {
                setPhone_1Valid(false);
                setPhone_1Error('Ungültige Telefonnummer');
            } else {
                setPhone_1Valid(true);
                setPhone_1Error('');
            }
        } else if (status === 'Wiedervorlage') {
            if (newPhone_1 === '' || /^[^a-zA-Z]+$/.test(newPhone_1)) {
                setWiedervorlagephone_1Valid(true);
                setPhone_1Error('');
            } else {
                setWiedervorlagephone_1Valid(false);
                setPhone_1Error('Ungültige Telefonnummer');
            }
        }
    };

    const handlePhone_1Blur = () => {
        setPhone_1Touched(true)
        validatePhone_1(inputState.phone_1, status);
    };

    // Validierung für phone_2
    const [phone_2Valid, setPhone_2Valid] = useState(true);
    const [phone_2Error, setPhone_2Error] = useState('');
    const [Wiedervorlagephone_2Valid, setWiedervorlagephone_2Valid] = useState(true);
    const [phone_2Touched, setPhone_2Touched] = useState(false);

    const handlePhone_2Change = (e) => {
        const newPhone_2 = e.target.value;
        setInputState({ ...inputState, phone_2: newPhone_2 });
        validatePhone_2(newPhone_2, status);
    };

    const validatePhone_2 = (newPhone_2, status) => {
        if (status === 'Termin') {
            if (newPhone_2 === '') {
                setPhone_2Valid(true);
                setPhone_2Error('');
            } else if (!/^[^a-zA-Z]+$/.test(newPhone_2)) {
                setPhone_2Valid(false);
                setPhone_2Error('Ungültige Telefonnummer');
            } else {
                setPhone_2Valid(true);
                setPhone_2Error('');
            }
        } else if (status === 'Wiedervorlage') {
            if (newPhone_2 === '' || /^[^a-zA-Z]+$/.test(newPhone_2)) {
                setWiedervorlagephone_2Valid(true);
                setPhone_2Error('');
            } else {
                setWiedervorlagephone_2Valid(false);
                setPhone_2Error('Ungültige Telefonnummer');
            }
        }
    };

    const handlePhone_2Blur = () => {
        setPhone_2Touched(true)
        validatePhone_2(inputState.phone_2, status);
    };

    // Validierung für jahresverbrauch
    const [jahresverbrauchValid, setJahresverbrauchValid] = useState(false);
    const [jahresverbrauchError, setJahresverbrauchError] = useState('');
    const [jahresverbrauchTouched, setJahresverbrauchTouched] = useState(false);

    const handleJahresverbrauchChange = (e) => {
        const newJahresverbrauch = e.target.value;
        setInputState({ ...inputState, jahresverbrauch: newJahresverbrauch });
        validateJahresverbrauch(newJahresverbrauch);
    };

    const validateJahresverbrauch = (newJahresverbrauch) => {
        if (newJahresverbrauch === '') {
            setJahresverbrauchValid(false);
            setJahresverbrauchError('Jahresverbrauch darf nicht leer sein.');
        } else if (!/^\d+$/.test(newJahresverbrauch)) {
            setJahresverbrauchValid(false);
            setJahresverbrauchError('Ungültiger Jahresverbrauch');
        } else {
            setJahresverbrauchValid(true);
            setJahresverbrauchError('');
        }
    };

    const handleJahresverbrauchBlur = () => {
        setJahresverbrauchTouched(true)
        validateJahresverbrauch(inputState.jahresverbrauch);
    };

    // Validierung für monatlichen Abschlag
    const [mtl_abschlagValid, setMtlAbschlagValid] = useState(false);
    const [mtl_abschlagError, setMtlAbschlagError] = useState('');
    const [mtl_abschlagTouched, setMtlAbschlagTouched] = useState(false);

    const handleMtlAbschlagChange = (e) => {
        const newMtlAbschlag = e.target.value;
        setInputState({ ...inputState, mtl_abschlag: newMtlAbschlag });
        validateMtlAbschlag(newMtlAbschlag);
    };

    const validateMtlAbschlag = (newMtlAbschlag) => {
        if (newMtlAbschlag === '') {
            setMtlAbschlagValid(false);
            setMtlAbschlagError('Monatlicher Abschlag darf nicht leer sein.');
        } else if (!/^\d+$/.test(newMtlAbschlag)) {
            setMtlAbschlagValid(false);
            setMtlAbschlagError('Ungültiger Monatlicher Abschlag');
        } else {
            setMtlAbschlagValid(true);
            setMtlAbschlagError('');
        }
    };

    const handleMtlAbschlagBlur = () => {
        setMtlAbschlagTouched(true)
        validateMtlAbschlag(inputState.mtl_abschlag);
    };

    const werberName = JSON.parse(localStorage.getItem('benutzer'));

    return (
        <FormStyled onSubmit={handleSubmit}>
            {error && <p className='error'>{error}</p>}
            <div className="input-control">
                <input
                    type="text"
                    value={strasse}
                    name={'strasse'}
                    placeholder="* Straße"
                    onChange={handleStrasseChange}
                    onBlur={handleStrasseBlur}
                    style={{ borderColor: (strasseValid || !strasseTouched) ? '' : 'red' }}
                />
                {strasseError && <span style={{ color: 'red' }}>{strasseError}</span>}
            </div>
            <div className="input-control">
                <input
                    type="text"
                    value={hausnummer}
                    name={'hausnummer'}
                    placeholder="* Hausnummer"
                    onChange={handleHausnummerChange}
                    onBlur={handleHausnummerBlur}
                    style={{ borderColor: (hausnummerValid || !hausnummerTouched) ? '' : 'red' }}
                />
                {hausnummerError && <span style={{ color: 'red' }}>{hausnummerError}</span>}
                {addressVisited && <p style={{ color: 'blue' }}>Diese Adresse wurde bereits besucht!</p>}
            </div>
            <div className="input-control">
                <input
                    type="text"
                    value={plz}
                    name={'plz'}
                    placeholder="* PLZ"
                    onChange={handlePLZChange}
                    onBlur={handlePLZBlur}
                    style={{ borderColor: (PLZValid || !PLZTouched) ? '' : 'red' }}
                />
                {PLZError && <span style={{ color: 'red' }}>{PLZError}</span>}
            </div>
            <div className="input-control">
                <input
                    type="text"
                    value={ort}
                    name={'ort'}
                    placeholder="* Ort"
                    onChange={handleOrtChange}
                    onBlur={handleOrtBlur}
                    style={{ borderColor: (OrtValid || !OrtTouched) ? '' : 'red' }}
                />
                {OrtError && <span style={{ color: 'red' }}>{OrtError}</span>}
            </div>

            {strasse !== undefined && hausnummer !== undefined && plz !== undefined && ort !== undefined && strasse.trim() !== '' && hausnummer.trim() !== '' && plz.trim() !== '' && ort.trim() !== '' && strasseValid && hausnummerValid && PLZValid && OrtValid && (
                <div className="selects input-control">
                    <select required value={status} name="status" id="status" onChange={handleInput('status')}>
                        <option value="" disabled>* Option auswählen</option>
                        <option value="Termin">Termin</option>
                        <option value="Wiedervorlage">Wiedervorlage</option>
                        <option value="Keine Interesse">Keine Interesse</option>
                        <option value="Nicht Da">Nicht Da</option>
                    </select>
                </div>
            )}
            {status === 'Termin' && (
                <>
                    <div className="input-control">
                        <input
                            type="text"
                            value={vorname}
                            name={'vorname'}
                            placeholder="* Vorname"
                            onChange={handleVornameChange}
                            onBlur={handleVornameBlur}
                            style={{ borderColor: (vornameValid || !vornameTouched) ? '' : 'red' }}
                        />
                        {vornameError && <span style={{ color: 'red' }}>{vornameError}</span>}
                    </div>
                    <div className="input-control">
                        <input
                            type="text"
                            value={nachname}
                            name={'nachname'}
                            placeholder="* Nachname"
                            onChange={handleNachnameChange}
                            onBlur={handleNachnameBlur}
                            style={{ borderColor: (nachnameValid || !nachnameTouched) ? '' : 'red' }}
                        />
                        {nachnameError && <span style={{ color: 'red' }}>{nachnameError}</span>}
                    </div>
                    <div className="input-control">
                        <input
                            type="email"
                            value={email}
                            name={'email'}
                            placeholder="* E-Mail-Adresse"
                            onChange={handleEmailChange}
                            onBlur={handleEmailBlur}
                            style={{ borderColor: (emailValid || !emailTouched) ? '' : 'red' }}
                        />
                        {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                    </div>
                    <div className="input-control">
                        <input
                            type="text"
                            value={phone_1}
                            name={'phone_1'}
                            placeholder="* Festnetz"
                            onChange={handlePhone_1Change}
                            onBlur={handlePhone_1Blur}
                            style={{ borderColor: (phone_1Valid || !phone_1Touched) ? '' : 'red' }}
                        />
                        {phone_1Error && <span style={{ color: 'red' }}>{phone_1Error}</span>}
                    </div>
                    <div className="input-control">
                        <input
                            type="text"
                            value={phone_2}
                            name={'phone_2'}
                            placeholder="Mobil"
                            onChange={handlePhone_2Change}
                            onBlur={handlePhone_2Blur}
                            style={{ borderColor: (phone_2Valid || !phone_2Touched) ? '' : 'red' }}
                        />
                        {phone_2Error && <span style={{ color: 'red' }}>{phone_2Error}</span>}
                    </div>
                    <div className="input-control">
                        <input
                            type="text"
                            value={jahresverbrauch}
                            name={'jahresverbrauch'}
                            placeholder="* Jahresverbrauch (kW/Jahr)"
                            onChange={handleJahresverbrauchChange}
                            onBlur={handleJahresverbrauchBlur}
                            style={{ borderColor: (jahresverbrauchValid || !jahresverbrauchTouched) ? '' : 'red' }}
                        />
                        {jahresverbrauchError && <span style={{ color: 'red' }}>{jahresverbrauchError}</span>}
                    </div>
                    <div className="selects input-control">
                        <select required value={personen_count} name="personen_count" id="personen_count" onChange={handleInput('personen_count')}>
                            <option value="" disabled> * Personen im Haushalt</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10+</option>
                        </select>
                    </div>
                    <div className="input-control">
                        <input
                            type="text"
                            value={mtl_abschlag}
                            name={'mtl_abschlag'}
                            placeholder="* Monatlicher Abschlag"
                            onChange={handleMtlAbschlagChange}
                            onBlur={handleMtlAbschlagBlur}
                            style={{ borderColor: (mtl_abschlagValid || !mtl_abschlagTouched) ? '' : 'red' }}
                        />
                        {mtl_abschlagError && <span style={{ color: 'red' }}>{mtl_abschlagError}</span>}
                    </div>
                    <div className="selects input-control">
                        <select value={art_flaeche} name="art_flaeche" id="art_flaeche" onChange={handleInput('art_flaeche')}>
                            <option value="" disabled>Art der Fläche</option>
                            <option value="Satteldach">Satteldach</option>
                            <option value="Flachdach">Flachdach</option>
                            <option value="Garagendach">Garagendach</option>
                            <option value="Walmdach">Walmdach</option>
                            <option value="Hallendach">Hallendach</option>
                            <option value="Blechdach">Blechdach</option>
                            <option value="Carport">Carport</option>
                        </select>
                    </div>
                    <div className="selects input-control">
                        <select value={dacheindeckung} name="dacheindeckung" id="dacheindeckung" onChange={handleInput('dacheindeckung')}>
                            <option value="" disabled>Dacheindeckung</option>
                            <option value="Ziegel">Ziegel</option>
                            <option value="Blech">Blech</option>
                            <option value="Kies">Kies</option>
                            <option value="Bitumen">Bitumen</option>
                            <option value="Faserzementplatten">Faserzementplatten</option>
                            <option value="Schiefer">Schiefer</option>
                            <option value="Beton">Beton</option>
                            <option value="Begrünt">Begrünt</option>
                        </select>
                    </div>
                    <div className="selects input-control">
                        <select value={ausrichtung_flaeche} name="ausrichtung_flaeche" id="ausrichtung_flaeche" onChange={handleInput('ausrichtung_flaeche')}>
                            <option value="" disabled>Ausrichtung der Fläche</option>
                            <option value="Süd">Süd</option>
                            <option value="Süd-West">Süd-West</option>
                            <option value="Süd-Ost">Süd-Ost</option>
                            <option value="Süd und Nord">Süd und Nord</option>
                            <option value="Süd-Ost und Nord">Süd-Ost und Nord</option>
                            <option value="Süd-West und Nord">Süd-West und Nord</option>
                            <option value="Süd-West-Ost und Nord">Süd-West-Ost und Nord</option>
                            <option value="Ost">Ost</option>
                            <option value="Ost und West">Ost und West</option>
                            <option value="Ost und Nord">Ost und Nord</option>
                            <option value="West">West</option>
                            <option value="West und Nord">West und Nord</option>
                            <option value="Nord">Nord</option>
                            <option value="Nord-West">Nord-West</option>
                            <option value="Nord-Ost">Nord-Ost</option>
                        </select>
                    </div>
                    <div className="input-control">
                        <DatePicker
                            id='datum'
                            placeholderText='* Termintag'
                            selected={datum}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            onChange={(selectedDate) => {
                                // Setze die Zeitzone auf UTC
                                const selectedDateUTC = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));

                                setInputState({ ...inputState, datum: selectedDateUTC });
                            }}
                        />
                    </div>
                    <div className="input-control">
                        <CustomTimePicker id='uhrzeit' value={timezeit} onChange={handleTimeChange} onHourValidChange={handleHourValidChange} />
                        {(!hourValid)}
                    </div>
                    <div className="input-control">
                        <textarea name="kommentar" value={kommentar} placeholder='Kommentar' id="kommentar" cols="30" rows="4" onChange={handleInput('kommentar')}></textarea>
                    </div>
                </>
            )}
            {status === 'Wiedervorlage' && (
                <>
                    <div className="input-control">
                        <input
                            type="text"
                            value={vorname}
                            name={'vorname'}
                            placeholder="Vorname"
                            onChange={handleVornameChange}
                            onBlur={handleVornameBlur}
                            style={{ borderColor: (WiedervorlagevornameValid || !vornameTouched) ? '' : 'red' }}
                        />
                        {vornameError && <span style={{ color: 'red' }}>{vornameError}</span>}
                    </div>
                    <div className="input-control">
                        <input
                            type="text"
                            value={nachname}
                            name={'nachname'}
                            placeholder="* Nachname"
                            onChange={handleNachnameChange}
                            onBlur={handleNachnameBlur}
                            style={{ borderColor: (nachnameValid || !nachnameTouched) ? '' : 'red' }}
                        />
                        {nachnameError && <span style={{ color: 'red' }}>{nachnameError}</span>}
                    </div>
                    <div className="input-control">
                        <input
                            type="email"
                            value={email}
                            name={'email'}
                            placeholder="E-Mail-Adresse"
                            onChange={handleEmailChange}
                            onBlur={handleEmailBlur}
                            style={{ borderColor: (WiedervorlageemailValid || !emailTouched) ? '' : 'red' }}
                        />
                        {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                    </div>
                    <div className="input-control">
                        <input
                            type="text"
                            value={phone_1}
                            name={'phone_1'}
                            placeholder="Festnetz"
                            onChange={handlePhone_1Change}
                            onBlur={handlePhone_1Blur}
                            style={{ borderColor: (Wiedervorlagephone_1Valid || !phone_1Touched) ? '' : 'red' }}
                        />
                        {phone_1Error && <span style={{ color: 'red' }}>{phone_1Error}</span>}
                    </div>
                    <div className="input-control">
                        <input
                            type="text"
                            value={phone_2}
                            name={'phone_2'}
                            placeholder="Mobil"
                            onChange={handlePhone_2Change}
                            onBlur={handlePhone_2Blur}
                            style={{ borderColor: (Wiedervorlagephone_2Valid || !phone_2Touched) ? '' : 'red' }}
                        />
                        {phone_2Error && <span style={{ color: 'red' }}>{phone_2Error}</span>}
                    </div>
                    <div className="input-control">
                        <DatePicker
                            id='datum'
                            placeholderText='* Termintag'
                            selected={datum}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            onChange={(selectedDate) => {
                                // Setze die Zeitzone auf UTC
                                const selectedDateUTC = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()));

                                setInputState({ ...inputState, datum: selectedDateUTC });
                            }}
                        />
                    </div>
                    <div className="input-control">
                        <CustomTimePicker id='uhrzeit' value={timezeit} onChange={handleTimeChange} onHourValidChange={handleHourValidChange} />
                        {(!hourValid)}
                    </div>
                    <div className="input-control">
                        <textarea name="kommentar" value={kommentar} placeholder='Kommentar' id="kommentar" cols="30" rows="4" onChange={handleInput('kommentar')}></textarea>
                    </div>
                </>
            )}

            {shouldShowButton() && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="submit-btn">
                    <Button
                        name={buttonName()}
                        bPad={'.8rem 1.6rem'}
                        bRad={'30px'}
                        bg={'var(--color-accent'}
                        color={'#fff'}
                    />
                </div>
            </div>
            )}
        </FormStyled>
    )
}


const FormStyled = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    input, textarea, select{
        font-family: inherit;
        font-size: inherit;
        outline: none;
        border: none;
        padding: .5rem 1rem;
        border-radius: 5px;
        border: 2px solid #fff;
        background: transparent;
        resize: none;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
        color: rgba(34, 34, 96, 0.9);
        &::placeholder{
            color: rgba(34, 34, 96, 0.4);
        }
    }
    .input-control{
        input{
            width: 100%;
        }
    }
    
    .selects{
        display: flex;
        justify-content: center;
        select{
            color: rgba(34, 34, 96, 0.4);
            &:focus, &:active{
                color: rgba(34, 34, 96, 1);
            }
        }
    }

    .submit-btn{
        button{
            box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
            &:hover{
                background: var(--color-green) !important;
            }
        }
    }
`;
export default Form;
