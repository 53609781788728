import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../../context/globalContext';
import styled from 'styled-components';
import { InnerLayout } from '../../styles/Layouts';

function Tracking() {
  const { trackWerber, TrackingData } = useGlobalContext();
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    trackWerber();
  }, []);

  useEffect(() => {
    // Funktion zur Sortierung der Daten
    const sortTrackingData = () => {
      const sorted = [...TrackingData].sort((a, b) => {
        // Funktion zur Überprüfung, ob der Zeitstempel nur Minuten enthält
        const hasOnlyMinutes = (str) => {
          return str.includes('min') && !str.includes('h') && !str.includes('d');
        };

        // Sortiere zuerst Einträge mit Minutenangaben
        if (hasOnlyMinutes(a.timeDifference) && !hasOnlyMinutes(b.timeDifference)) {
          return -1;
        }
        if (!hasOnlyMinutes(a.timeDifference) && hasOnlyMinutes(b.timeDifference)) {
          return 1;
        }

        // Sortiere dann Einträge ohne "d" vor denen mit "d"
        if (!a.timeDifference.includes('d') && b.timeDifference.includes('d')) {
          return -1;
        }
        if (a.timeDifference.includes('d') && !b.timeDifference.includes('d')) {
          return 1;
        }

        // Sortiere aufsteigend nach der Zeitdifferenz
        const timeA = getTimeValue(a.timeDifference);
        console.log("timeA", timeA)
        const timeB = getTimeValue(b.timeDifference);
        console.log("timeB", timeB)
        return timeA - timeB;
      });
      setSortedData(sorted);
    };

    sortTrackingData();
    console.log("sortedData", sortedData)
  }, [TrackingData]);

  // Funktion zur Extrahierung der Zeitdifferenz
  const getTimeValue = (str) => {
    const num = parseInt(str.match(/\d+/)[0]);
    const unit = str.includes('h') ? 60 : 1; // 1 for minutes, 60 for hours
    return num * unit;
  };


  return (
      <InnerLayout>
      <h1>Tracking</h1>
      <Table className="tracking-table">
        <thead>
            <TableRow>
              <TableHeader>Werber</TableHeader>
              <TableHeader>Adresse</TableHeader>
              <TableHeader>Zeitstempel</TableHeader>
            </TableRow>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item._id}</TableCell>
              <TableCell>{item.plz}, {item.ort}, {item.strasse} {item.hausnummer}</TableCell>
              <TableCell>{item.timeDifference}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      </InnerLayout>
  );
}

export default Tracking;


const Table = styled.table`
  margin-top: 1.5rem;
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow:hidden;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #FCF6F9;
  }
  &:not(:last-child) {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  padding: 12px;
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  background-color: #FCF6F9;
`;



