import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InnerLayout } from '../../styles/Layouts';
import { plus, left } from '../../utils/Icons';
import MitarbeiterItem from './MitarbeiterItem';
import MitarbeiterForm from './MitarbeiterForm';
import Box from '@mui/material/Box';
import { useGlobalContext } from '../../context/globalContext';
import Button from '../../Components/Button/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function Mitarbeiter() {
    const [showForm, setShowForm] = useState(false);

    const toggleForm = () => {
        setShowForm(!showForm);
    };

    const handleMitarbeiterFormSubmit = () => {
        toggleForm(); // Formular schließen, wenn Benutzer angelegt wurde
      };


    const [value, setValue] = useState(0);
    const { getAllMitarbeiter, AllMitarbeiter, updateMitarbeiter, deleteMitarbeiter } = useGlobalContext();

    useEffect(() => {
        getAllMitarbeiter();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [searchVorname, setSearchVorname] = useState('');


    const [selectedFilters, setSelectedFilters] = useState([]);
    let filters = ["Admin", "Verkäufer", "Werber", "Bestätiger"];

    const handleFilterButtonClick = (selectedCategory) => {
      if (selectedFilters.includes(selectedCategory)) {
        setSelectedFilters(prevFilters => prevFilters.filter(el => el !== selectedCategory));
      } else {
        // Überprüfen, ob ein anderer Filter bereits ausgewählt ist
        const otherSelectedFilters = selectedFilters.filter(filter => filter !== "alle Kategorien");
        if (otherSelectedFilters.length > 0) {
          setSelectedFilters(prevFilters => [...prevFilters, selectedCategory]);
        } else {
          setSelectedFilters([selectedCategory]);
        }
      }
    };
    
    useEffect(() => {
      console.log("selectedFilters", selectedFilters);
    }, [selectedFilters]);

    return (
        <MitarbeiterStyled> 
            <InnerLayout>
          <Box sx={{ width: '100%'}}>
            <div className='sticky-header'>
                <h1>Mitarbeiterübersicht</h1>
                {!showForm && (
                <div className="create-user-btn">
                    <Button
                        name={'Benutzer erstellen'}
                        icon={plus}
                        bPad={'.8rem 1.6rem'}
                        bRad={'30px'}
                        bg={'var(--color-accent'}
                        color={'#fff'}
                        onClick={toggleForm}
                    />
                </div>
                )}
                <div className="search-inputs">
                <Form>
                    <InputGroup className='input-control'>
                        <Form.Control
                            onChange={(e) => setSearchVorname(e.target.value)}
                            placeholder='Suche Mitarbeiter'
                        />
                    </InputGroup>
                </Form>
                </div>
                <div className="buttons-container">
                        {filters.map((category, idx) => (
                        <button
                            onClick={() => handleFilterButtonClick(category)}
                            className={`button ${
                            selectedFilters?.includes(category) ? "active" : ""
                            }`}
                            key={`filters-${idx}`}
                        >
                            {category}
                        </button>
                        ))}
                </div>
                </div>
                        <div className="income-content">
                            <div className="incomes">
                                {AllMitarbeiter
                                .filter((mitarbeiter) => {
                                    const searchLowerCase = searchVorname.toLowerCase();
                                    const searchLowerCaseNachname = searchVorname.toLowerCase(); // Nachname hinzugefügt
                                    return searchLowerCase === '' || mitarbeiter.vorname.toLowerCase().includes(searchLowerCase) || mitarbeiter.nachname.toLowerCase().includes(searchLowerCaseNachname); // Nachname durchsuchen
                                })
                                .filter((mitarbeiter) => {
                                    const searchLowerCase = selectedFilters.map(filter => filter);
                                    return searchLowerCase.length === 0 || searchLowerCase.includes(mitarbeiter.rolle);
                                }).map((mitarbeiter) => {
                                    const {_id, vorname, nachname, rolle, phone_1, phone_2, email, passwort} = mitarbeiter;
                                    return <MitarbeiterItem
                                        key={_id}
                                        id={_id} 
                                        vorname={vorname} 
                                        nachname={nachname} 
                                        rolle={rolle} 
                                        phone_1={phone_1} 
                                        phone_2={phone_2}
                                        email={email}
                                        passwort={passwort}
                                        updateMitarbeiter = {updateMitarbeiter}
                                        deleteMitarbeiter = {deleteMitarbeiter}
                                        indicatorColor="var(--color-green)"
                                    />
                                })}
                            </div>
                        </div>
                {showForm && (
                    <div className="form-container">
                        <Button
                        className="close-btn"
                        icon={left}
                        bPad={'.5rem .8rem'}
                        bRad={'30px'}
                        bg={'var(--color-accent'}
                        color={'#fff'}
                        onClick={toggleForm}
                    />
                        <MitarbeiterForm onSubmit={handleMitarbeiterFormSubmit} />
                    </div>
                )}
                </Box>
            </InnerLayout>
        </MitarbeiterStyled>
    );
}

const MitarbeiterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  
  input, textarea, select{
    font-family: inherit;
    font-size: inherit;
    outline: none;
    border: none;
    padding: .5rem 1rem;
    border-radius: 5px;
    border: 2px solid #fff;
    background: transparent;
    resize: none;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    color: rgba(34, 34, 96, 0.9);
    &::placeholder{
        color: rgba(34, 34, 96, 0.4);
    }
}
.input-control{
    input{
        width: 100%;
    }
}

.selects{
    display: flex;
    justify-content: flex-end;
    select{
        color: rgba(34, 34, 96, 0.4);
        &:focus, &:active{
            color: rgba(34, 34, 96, 1);
        }
    }
}

.income-content {
    margin-top: 30px;
    display: flex;
    gap: 2rem;
   
    max-height: calc(100vh - 40vh);
    overflow-y: auto;
    @media (max-width: 768px) {
      max-height: calc(100vh - 80vh);
      margin-top: 15px;
    }

    .incomes {
        flex: 1;
    }
}

    /* Stile für die Suchfelder */
    .search-inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;

        .my-3 {
            flex: 1; /* Die Suchfelder sollen gleichmäßig wachsen */
        }
    }

    .buttons-container {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    
    .button {
      border: 2px solid white;
      background: transparent;
      margin: 5px;
      border-radius: 10px;
      padding: 10px 20px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      font-size: 16px;
    }
    
    .active {
      background-color: var(--color-accent) !important;
      border: 2px solid var(--color-accent);
      color: #fff;
    }


    .create-user-btn {
        position: absolute;
        top: -1rem;
        right: -0.5rem;
        z-index: 1;

        button {
            box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
      
            &:hover {
              background: var(--color-green) !important;
            }
          }
    }

    .form-container {
        position: fixed;
        top: 0;
        right: 0;
        width: 60vh;
        max-height: 80vh;
        box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
        animation: slideIn 0.3s ease forwards;
        background: #FCF6F9;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        padding: 1rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        color: #222260;
        z-index: 9;
        display: flex;
        flex-direction: row;
        overflow-y: auto;
        justify-content: space-evenly;
    }

    .user-form {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .close-btn {
        position: absolute;
        top: 1rem;
        left: 1rem;
        background: transparent;
        border: none;
        cursor: pointer;
    }

    @keyframes slideIn {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    .sticky-header {
        position: sticky;
        top: 2rem;
      }
`;

export default Mitarbeiter;