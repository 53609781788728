import {home, mitarbeiter, termine, rangliste, tracking, statistik} from '../utils/Icons'

export const menuItems = [
    {
        id: 1,
        title: 'Home',
        icon: home,
        link: '/home'
    },
    {
        id: 2,
        title: "Mitarbeiter",
        icon: mitarbeiter,
        link: "/home",
    },
    {
        id: 3,
        title: "Termine",
        icon: termine,
        link: "/home",
    },
    {
        id: 4,
        title: "Rangliste",
        icon: rangliste,
        link: "/home",
    },
    {
        id: 5,
        title: "Tracking",
        icon: tracking,
        link: "/home",
    },
    {
        id: 6,
        title: "Statistik",
        icon: statistik,
        link: "/home",
    },
]