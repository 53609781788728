import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { InnerLayout } from '../../styles/Layouts';
import { useGlobalContext } from '../../context/globalContext';
import TerminItem from '../Verkäufer/TerminItem';
import Button from '../../Components/Button/Button';
import { csv } from '../../utils/Icons';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export default function Termine() {
    const [value, setValue] = useState(0);
    const { getAllTerminstaten, terminstatenList, getAllActiveWerber, werberList, getAllActiveVerkaeufer, verkaeuferList, getAllTermine, AllTermine, storniereTermin, deleteTermin, updateTermin } = useGlobalContext();
    const angemeldeterVerkaeufer = JSON.parse(localStorage.getItem('benutzer'));

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const getYesterdayDate = () => {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1); // Einen Tag von heute abziehen
      const year = yesterday.getFullYear();
      const month = String(yesterday.getMonth() + 1).padStart(2, '0');
      const day = String(yesterday.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const [searchVorname, setSearchVorname] = useState('');
    const [startDate, setStartDate] = useState(getTodayDate());
    const [endDate, setEndDate] = useState(getTodayDate());
    const [searchStrasse, setSearchStrasse] = useState('');
    const [selectedTerminstatus, setSelectedTerminstatus] = useState('');

    const handleTerminstatusChange = (event) => {
      setSelectedTerminstatus(event.target.value);
    };

    function formatDateToUTCString(dateString) {
      const date = new Date(dateString);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
      const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');
    
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
    }

    const todayUTC = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())).toISOString().split('T')[0];

    useEffect(() => {
      getAllActiveWerber();
      getAllTerminstaten();
      getAllActiveVerkaeufer();
      getAllTermine(startDate, endDate);
      console.log("todayUTC", todayUTC)
    }, [startDate, endDate]);

    const [filteredTermine, setFilteredTermine] = useState([]);

    useEffect(() => {
      setFilteredTermine(
        AllTermine
          .filter((termin) => termin.zugewiesener_verkaeufer === angemeldeterVerkaeufer)
          .filter((termin) => {
            const searchLowerCase = searchStrasse.toLowerCase();
            return searchLowerCase === '' || termin.strasse.toLowerCase().includes(searchLowerCase);
          })
          .filter((termin) => {
            const searchLowerCase = searchVorname.toLowerCase();
            const searchLowerCaseNachname = searchVorname.toLowerCase(); // Nachname hinzugefügt
            return searchLowerCase === '' || termin.vorname.toLowerCase().includes(searchLowerCase) || termin.nachname.toLowerCase().includes(searchLowerCaseNachname); // Nachname durchsuchen
          })
          .filter((termin) => {
            // Hier das Datum um einen Tag erhöhen und vergleichen
            const dayAfter = new Date(termin.datum);
            dayAfter.setDate(dayAfter.getDate() + 1);
            return startDate === '' || dayAfter > new Date(startDate);
          })
          .filter((termin) => {
            // Hier das Datum um einen Tag reduzieren und vergleichen
            const dayBefore = new Date(termin.datum);
            dayBefore.setDate(dayBefore.getDate() - 1);
            return endDate === '' || dayBefore < new Date(endDate);
          })
          .filter((termin) => {
            const searchLowerCase = selectedTerminstatus.toLowerCase();
            return searchLowerCase === '' || termin.status_verkaeufer.toLowerCase().includes(searchLowerCase);
          })
      );
    }, [AllTermine, angemeldeterVerkaeufer, searchStrasse, searchVorname, startDate, endDate, selectedTerminstatus]);

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const disableDropdownCondition = filteredTermine.some(termin => new Date(termin.datum) <= new Date(getTodayDate()) && new Date(termin.datum) >= new Date(getYesterdayDate()) && termin.status_verkaeufer === "Kein Status");
  const [isHeaderExpanded, setIsHeaderExpanded] = useState(false);
    return (
      <TermineStyled>
          <InnerLayout>
          <Box sx={{ width: '100%' }}>
          <div className={`sticky-header ${isHeaderExpanded ? 'expanded' : ''}`}>
            <div className="header-icon" onClick={() => setIsHeaderExpanded(!isHeaderExpanded)}>
              {isHeaderExpanded ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <h1 style={{ textAlign: 'center' }}>Terminübersicht</h1>
          
        <div className="search-inputs">
        <Form>
            <InputGroup className='input-control'>
                <Form.Control
                    onChange={(e) => setSearchStrasse(e.target.value)}
                    placeholder='Suche Strasse'
                />
            </InputGroup>
        </Form>
        <Form>
            <InputGroup className='input-control'>
                <Form.Control
                    onChange={(e) => setSearchVorname(e.target.value)}
                    placeholder='Suche Kunde'
                />
            </InputGroup>
        </Form>
        <Form>
        <InputGroup className='input-control'>
            <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => {
                    if (e.target.value) { // Überprüfung, ob ein Datum ausgewählt wurde
                        const selectedDate = new Date(e.target.value);
                        selectedDate.setDate(selectedDate.getDate()); // Einen Tag abziehen
                        const formattedDate = selectedDate.toISOString().split('T')[0]; // Formatierung in "YYYY-MM-DD"
                        setStartDate(formattedDate);
                    } else {
                        setStartDate(''); // Wenn kein Datum ausgewählt ist, leeren String setzen
                    }
                }}
                disabled={disableDropdownCondition}
                placeholder='Startdatum eingeben'
            />
        </InputGroup>
        </Form>
        <Form>
            <InputGroup className='input-control'>
                <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      if (e.target.value) {
                          const selectedDate = new Date(e.target.value);
                          selectedDate.setDate(selectedDate.getDate()); // Einen Tag hinzufügen
                          const formattedDate = selectedDate.toISOString().split('T')[0];
                          setEndDate(formattedDate);
                      } else {
                          setEndDate('');
                      }
                    }}
                    disabled={disableDropdownCondition}
                    placeholder='Enddatum eingeben'
                />
            </InputGroup>
        </Form>
        <Form>
            <InputGroup className='selects input-control'>
            <select id="terminstatusDropdown" onChange={handleTerminstatusChange} value={selectedTerminstatus || ''}>
                      <option value="">Alle Termine</option>
                      <option value="Kein Status">Kein Status</option>
                      <option value="Abschluss (1)">Abschluss</option>
                      <option value="Konnte nicht verkaufen (2)">Konnte nicht verkaufen</option>
                      <option value="Angebot (3)">Angebot</option>
                      <option value="Überleger (4)">Überleger</option>
                      <option value="Nicht da (5)">Nicht da</option>
                      <option value="Keine Interesse (6)">Keine Interesse</option>
                      <option value="Technisch nicht möglich (7)">Technisch nicht möglich</option>
                      <option value="Storniert">Storniert</option>
                    </select>
            </InputGroup>
        </Form>
    </div>
      </div>
      <div className='anzahl-header'>
                <h3>Anzahl: {filteredTermine.length}</h3>
      </div>
      <div className="income-content">
        <div className="incomes">
          {filteredTermine.map((termin) => {
            const {_id, adresse, strasse, hausnummer, plz, ort, vorname, nachname, email, phone_1, phone_2, werber,  datum, createdAt, uhrzeit, status, kommentar, terminstatus, jahresverbrauch, personen_count, mtl_abschlag, art_flaeche, dacheindeckung, ausrichtung_flaeche, terminbestaetigung, zugewiesener_verkaeufer, status_verkaeufer} = termin;
            return <TerminItem
              key={_id}
              id={_id} 
              adresse={adresse}
              strasse={strasse}
              hausnummer={hausnummer}
              plz={plz}
              ort={ort}
              vorname={vorname}
              nachname={nachname}
              email={email}
              phone_1={phone_1}
              phone_2={phone_2} 
              datum={datum}
              createdAt={createdAt} 
              uhrzeit={uhrzeit} 
              status={status}
              kommentar={kommentar} 
              terminstatus={terminstatus}
              jahresverbrauch={jahresverbrauch}
              personen_count={personen_count}
              mtl_abschlag={mtl_abschlag}
              art_flaeche={art_flaeche}
              dacheindeckung={dacheindeckung}
              ausrichtung_flaeche={ausrichtung_flaeche}
              terminbestaetigung={terminbestaetigung}
              zugewiesener_verkaeufer={zugewiesener_verkaeufer}
              status_verkaeufer={status_verkaeufer}
              werber={werber}
              verkaeuferList={verkaeuferList}
              indicatorColor="var(--color-green)"
              storniereTermin={storniereTermin}
              deleteTermin = {deleteTermin}
              updateTermin = {updateTermin}
            />
          })}
        </div>
      </div>
       
      </Box>
      </InnerLayout>
      </TermineStyled>
    );
  }
  
  const TermineStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: auto;
  input, textarea, select{
    font-family: inherit;
    font-size: inherit;
    outline: none;
    border: none;
    padding: .5rem 1rem;
    border-radius: 5px;
    border: 2px solid #fff;
    background: transparent;
    resize: none;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    color: rgba(34, 34, 96, 0.9);
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
    &::placeholder{
        color: rgba(34, 34, 96, 0.4);
    }
}
.input-control{
    input{
        width: 100%;
    }
}

.selects{
    display: flex;
    justify-content: flex-end;
    select{
        color: rgba(34, 34, 96, 0.4);
        &:focus, &:active{
            color: rgba(34, 34, 96, 1);
        }
    }
}

.income-content {
  margin-top: 20px;
  display: flex;
  gap: 2rem;

  max-height: calc(100vh - 40vh);
  overflow-y: auto;
  @media (max-width: 768px) {
    max-height: calc(100vh - 20vh);
    margin-top: 15px;
  }

  .incomes {
      flex: 1;
      @media (max-width: 768px) {
        width: 100%;
      }
  }
}

    .export-btn {
      position: absolute;
      top: -1rem;
      right: -0.5rem;
      z-index: 1;
  
      button {
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
  
        &:hover {
          background: var(--color-green) !important;
        }
      }

      @media (max-width: 768px) {
        display: none;
    }
    }

    .such-btn {
      margin-top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      
      button {
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
      
        &:hover {
          background: var(--color-green) !important;
        }
      }
    }
    
    /* Stile für die Suchfelder */
    .search-inputs {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      margin-top: 1rem;

      .my-3 {
          flex: 1; /* Die Suchfelder sollen gleichmäßig wachsen */
      }

      @media (max-width: 768px) {
          flex-direction: column;
      }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
    
    .button {
      border: 2px solid white;
      background: transparent;
      margin: 5px;
      border-radius: 10px;
      padding: 10px 20px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      font-size: 16px;
    }
    
    .active {
      background-color: var(--color-accent) !important;
      border: 2px solid var(--color-accent);
      color: #fff;
    }
    .sticky-header {
      position: sticky;
      top: 2rem;
      z-index: 999999;
      padding: 10px;
      transition: max-height 0.3s ease-in-out;
  
      @media (max-width: 768px) {
        top: 0;
        max-height: 50px;
        overflow: hidden;
  
        &.expanded {
          max-height: 500px; /* Höhere Höhe für ausgeklappten Zustand */
        }
      }
    }
  
    .header-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 10px;
  
      @media (max-width: 768px) {
        display: block;
      }
    }
  
    .anzahl-header {
      margin: 1rem;
    }
`;
  