export const dashboard = <i className="fa-solid fa-chart-line"></i>
export const transactions = <i className="fa-solid fa-credit-card"></i>
export const categories = <i className="fa-solid fa-tags"></i>
export const accounts = <i className="fa-solid fa-wallet"></i>
export const settings = <i className="fa-solid fa-cog"></i>
export const trend = <i className="fa-solid fa-money-bill-trend-up"></i>
export const expenses = <i className="fa-solid fa-money-bill-transfer"></i>
export const money = <i className="fa-solid fa-money-bill"></i>
export const freelance = <i className ="fa-solid fa-earth-americas"></i>
export const stocks = <i className="fa-solid fa-arrow-trend-up"></i>
export const bitcoin = <i className="fa-brands fa-bitcoin"></i>
export const piggy = <i className="fa-solid fa-piggy-bank"></i>
export const yt = <i className="fa-brands fa-youtube"></i>
export const card = <i className="fa-brands fa-cc-visa"></i>
export const users = <i className="fa-solid fa-users-between-lines"></i>
export const dollar = <i className="fa-solid fa-dollar-sign"></i>
export const calender = <i className="fa-solid fa-calendar"></i>
export const comment = <i className="fa-solid fa-comment"></i>
export const plus = <i className="fa-solid fa-plus"></i>
export const trash = <i className="fa-solid fa-trash"></i>
export const signout = <i className="fa-solid fa-right-from-bracket"></i>
export const takeaway = <i className="fa-solid fa-utensils"></i>
export const clothing = <i className="fa-solid fa-shirt"></i>
export const book = <i className="fa-solid fa-book-open"></i>
export const food = <i className="fa-solid fa-bowl-food"></i>
export const medical = <i className="fa-solid fa-briefcase-medical"></i>
export const tv = <i className="fa-solid fa-tv"></i>
export const circle = <i className="fa-solid fa-circle-dot"></i>
export const home = <i class="fa-solid fa-house"></i>
export const mitarbeiter = <i class="fa-solid fa-users"></i>
export const termine = <i class="fa-solid fa-calendar"></i>
export const rangliste = <i class="fa-solid fa-ranking-star"></i>
export const tracking = <i class="fa-solid fa-location-dot"></i>
export const logout = <i class="fa-solid fa-right-from-bracket"></i>
export const clock = <i class="fa-solid fa-clock"></i>
export const pdf = <i class="fa-solid fa-file-pdf"></i>
export const csv = <i class="fa-solid fa-file-csv"></i>
export const left = <i class="fa-solid fa-chevron-left"></i>
export const x = <i class="fa-solid fa-x"></i>
export const user = <i class="fa-solid fa-user"></i>
export const phone = <i class="fa-solid fa-phone"></i>
export const email_icon = <i class="fa-solid fa-envelope"></i>
export const eye_open = <i class="fa-solid fa-eye"></i>
export const eye_close = <i class="fa-solid fa-eye-slash"></i>
export const zuweisen = <i class="fa-solid fa-share"></i>
export const statistik = <i class="fa-solid fa-chart-simple"></i>