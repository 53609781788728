import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Admin from './Admin';
import LogIn from './LogIn';
import Werber from './Werber';
import Verkäufer from './Verkäufer';
import Bestätiger from './Bestätiger';

// Funktion zur Überprüfung des Tokens
const isTokenValid = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('Token not found in localStorage');
    return false;
  }
  return true;
};

// Funktion zur Überprüfung der Benutzerrolle
const hasUserRole = (role) => {
  const userRole = localStorage.getItem('userRole');
  return userRole === role;
};

const PrivateRoute = ({ element, path, expectedRole }) => {
  if (!isTokenValid()) {
    console.error('Invalid token. Redirecting to login page.');
    return <Navigate to="/" />;
  }
  if (!hasUserRole(`"${expectedRole}"`)) {
    console.error('Unauthorized access. Redirecting to login page.');
    return <Navigate to="/" />;
  }
  return element;
};


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LogIn />} />
        <Route path="/admin" element={<PrivateRoute element={<Admin />} expectedRole="Admin" />} />
        <Route path="/werber" element={<PrivateRoute element={<Werber />} expectedRole="Werber" />} />
        <Route path="/verkaeufer" element={<PrivateRoute element={<Verkäufer />} expectedRole="Verkäufer" />} />
        <Route path="/bestaetiger" element={<PrivateRoute element={<Bestätiger />} expectedRole="Bestätiger" />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
