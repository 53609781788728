import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useGlobalContext } from '../../context/globalContext';


function TermineALT() {
    const { getAllActiveWerber, werberList, setWerberList,getTermineByWerber,termineDesWerbers } = useGlobalContext();
    const [selectedWerber, setSelectedWerber] = useState(null); // Zustand für ausgewählten Werber hinzufügen



    useEffect(() => {
        getAllActiveWerber();
    }, []);

    const handleSelectChange = (event) => {
        setSelectedWerber(event.target.value);
        getTermineByWerber(event.target.value);
        console.log("selectedWerber111:", selectedWerber);
        console.log("termineDesWerbers111:", termineDesWerbers);
    };


    return (
        <TermineStyled>
            <div className="termin-view">
                <div className="selects input-control">
                    <label htmlFor="werberDropdown">Werber:</label>
                    <select id="werberDropdown" onChange={handleSelectChange} value={selectedWerber || ''}>
                        <option value="" disabled>
                            Bitte wählen
                        </option>
                        {werberList.map((werber, index) => (
                            <option key={index} value={werber}>
                                {werber}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="termine-content">
                <div className="termine">
                    <div className="termine-header">Termine:</div> {/* Text über den Adressen */}
                    {/* Hier werden die Adressen des ausgewählten Werbers in roter Schrift fett angezeigt */}
                    <AdressenList>
                    {console.log("termineDesWerbers555:", termineDesWerbers)}
                        {termineDesWerbers.map((termin, index) => (
                            <Adressen key={index}>{termin.adresse}</Adressen>
                        ))}
                    </AdressenList>
                </div>
            </div>
        </TermineStyled>
    );
}

const AdressenList = styled.div`
    color: red;
    font-weight: bold;
`;

const Adressen = styled.div`
    margin-bottom: 500px;
`;
const TermineStyled = styled.div`
    display: flex;
    overflow: auto;
    .termin-view {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FCF6F9;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        padding: 1rem;
        margin: 1rem 0;
        font-size: 2rem;
        gap: .5rem;
        label {
            font-size: 1.5rem;
            font-weight: 600;
            color: var(--color-dark);
        }
        select {
            font-size: 1.5rem;
            padding: 0.5rem;
            border-radius: 5px;
            border: 1px solid #ccc;
            background-color: #fff;
            width: 300px; /* Breite des Dropdown-Menüs anpassen */
        }
    }
    .termine-content {
        display: flex;
        gap: 2rem;
        .termine {
            flex: 1;
        }
    .input-control{
        input{
            width: 100%;
        }
    }

    .selects{
        display: flex;
        justify-content: flex-end;
        select{
            color: rgba(34, 34, 96, 0.4);
            &:focus, &:active{
                color: rgba(34, 34, 96, 1);
            }
        }
    }
    }
`;



export default TermineALT;