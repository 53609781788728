import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styled from 'styled-components'
import { dateFormat } from '../../utils/dateFormat';
import { x, user, calender, pdf, clock, comment, tracking } from '../../utils/Icons';
import Button from '../../Components/Button/Button';
import {handleEditTermin} from "../../Components/EditTermin/EditTermin";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { TerminPDF } from './TerminPDF';
import { motion } from "framer-motion";
import { FaTimes } from 'react-icons/fa';
import CustomTimePicker from '../../Components/CustomTimePicker/CustomTimePicker';


const MySwal = withReactContent(Swal);


function TerminItem({
  type,
  id,
  adresse,
  strasse,
  hausnummer,
  plz,
  ort,
  vorname,
  nachname,
  email,
  phone_1,
  phone_2,
  datum,
  createdAt,
  uhrzeit,
  status,
  terminstatus,
  werber,
  verkaeuferList,
  kommentar,
  mtl_abschlag,
  jahresverbrauch,
  personen_count,
  art_flaeche,
  dacheindeckung,
  ausrichtung_flaeche,
  terminbestaetigung,
  zugewiesener_verkaeufer,
  status_verkaeufer,
  isChecked,
  onCheckboxChange,
  storniereTermin = { storniereTermin },
  deleteTermin = { deleteTermin },
  updateTermin = { updateTermin },
  indicatorColor
}) {

  const [showDetails, setShowDetails] = useState(false);
  const [checkboxMessage, setCheckboxMessage] = useState("");

  const toggleDetails = () => {
    if (!isChecked) { // Only toggle details if the checkbox is not checked
      setShowDetails(!showDetails);
    }
  };

  const handleCheckboxChange = (event) => {
    if (terminbestaetigung === "Ja" && status === "Termin" && terminstatus === "aktiv") {
      onCheckboxChange(id, event.target.checked);
      setCheckboxMessage(""); // Clear the message
    } else {
      event.preventDefault(); // Prevent the checkbox from changing state
      setCheckboxMessage("Ein Termin muss bestätigt werden und aktiv sein, bevor er zugewiesen werden kann!");
    }
  };

  return (
    <div>
      {showDetails ? (
        <DetailsCard
          id={id}
          adresse={adresse}
          strasse={strasse}
          hausnummer={hausnummer}
          plz={plz}
          ort={ort}
          vorname={vorname}
          nachname={nachname}
          email={email}
          phone_1={phone_1}
          phone_2={phone_2}
          datum={datum}
          createdAt={createdAt}
          uhrzeit={uhrzeit}
          status={status}
          terminstatus={terminstatus}
          werber={werber}
          verkaeuferList={verkaeuferList}
          kommentar={kommentar}
          mtl_abschlag={mtl_abschlag}
          jahresverbrauch={jahresverbrauch}
          personen_count={personen_count}
          art_flaeche={art_flaeche}
          dacheindeckung={dacheindeckung}
          ausrichtung_flaeche={ausrichtung_flaeche}
          terminbestaetigung={terminbestaetigung}
          zugewiesener_verkaeufer={zugewiesener_verkaeufer}
          status_verkaeufer={status_verkaeufer}
          indicatorColor={indicatorColor}
          storniereTermin={storniereTermin}
          deleteTermin={deleteTermin}
          updateTermin = {updateTermin}
          closeDetails={toggleDetails} />
      ) : (
        <SmallCard
          id={id}
          adresse={adresse}
          strasse={strasse}
          hausnummer={hausnummer}
          plz={plz}
          ort={ort}
          datum={datum}
          uhrzeit={uhrzeit}
          status={status}
          terminstatus={terminstatus}
          werber={werber}
          kommentar={kommentar}
          terminbestaetigung={terminbestaetigung}
          zugewiesener_verkaeufer={zugewiesener_verkaeufer}
          status_verkaeufer={status_verkaeufer}
          indicatorColor={indicatorColor}
          isChecked={isChecked}
          onCheckboxChange={handleCheckboxChange}
          checkboxMessage={checkboxMessage}
          openDetails={toggleDetails} />
      )}
    </div>
  );
}


function SmallCard({ id,
  adresse,
  strasse,
  hausnummer,
  plz,
  ort,
  datum,
  uhrzeit,
  status,
  terminstatus,
  terminbestaetigung,
  zugewiesener_verkaeufer,
  status_verkaeufer,
  werber,
  kommentar,
  indicatorColor, isChecked, onCheckboxChange, openDetails, checkboxMessage }) {

  const terminbestaetigungStyle = {
    color: terminbestaetigung === "Ja" ? 'green' : 'red'
  };

  const status_verkaeufer_style = {
    color: status_verkaeufer === "Abschluss (1)" ? 'green' :
           status_verkaeufer === "Storniert" ? 'red' :
           '#222260'
  };

  return (
    <motion.div
      className="small-card"
      onClick={openDetails}
      initial={{ opacity: 0, scale: 0.9, y: 0 }} // Startzustand
      animate={{ opacity: 1, scale: 1, y: 0 }} // Zustand während der Anzeige
      exit={{ opacity: 0, scale: 0.9, y: 0 }} // Zustand beim Schließen
      transition={{ duration: 0.3, ease: "easeOut" }}
    >
      <SmallCardStyled terminstatus={terminstatus} indicator={indicatorColor}>
        <div className="content">
          <h5>{plz}, {ort}, {strasse} {hausnummer}</h5>
          <div className="inner-content">
            <div className="text">
              <p>{calender} {dateFormat(datum)}</p>
              <p>{clock} {uhrzeit}</p>
              <p>
                Termin bestätigt:
                <span style={terminbestaetigungStyle}> <BoldText>{terminbestaetigung}</BoldText></span>
              </p>
              {zugewiesener_verkaeufer !== "Nicht zugewiesen" && (
                <p>{user} {zugewiesener_verkaeufer}</p>
              )}
              <p>
                Status Verkäufer:
                <span style={status_verkaeufer_style}><BoldText>{status_verkaeufer}</BoldText></span>
              </p>
            </div>

          </div>
        </div>
        {checkboxMessage && <Message>{checkboxMessage}</Message>}
        <div>
          <StyledCheckbox
            type="checkbox"
            className="smallcard-checkbox"
            checked={isChecked}
            onChange={onCheckboxChange}
            onClick={(e) => e.stopPropagation()} // Prevent the click event from propagating to the card
          />
          </div>
      </SmallCardStyled>
    </motion.div>
  )
}


function DetailsCard({
  id,
  adresse,
  strasse,
  hausnummer,
  plz,
  ort,
  vorname,
  nachname,
  email,
  phone_1,
  phone_2,
  datum,
  uhrzeit,
  createdAt,
  status,
  terminstatus,
  werber,
  verkaeuferList,
  kommentar,
  jahresverbrauch,
  personen_count,
  mtl_abschlag,
  art_flaeche,
  dacheindeckung,
  ausrichtung_flaeche,
  terminbestaetigung,
  zugewiesener_verkaeufer,
  status_verkaeufer,
  storniereTermin,
  deleteTermin,
  updateTermin,
  indicatorColor,
  closeDetails
}) {

  const [fields, setFields] = useState({
    terminbestaetigung: terminbestaetigung,
    zugewiesener_verkaeufer: zugewiesener_verkaeufer,
    status_verkaeufer: status_verkaeufer,
  });
  const [initialFields, setInitialFields] = useState({
    terminbestaetigung: terminbestaetigung,
    zugewiesener_verkaeufer: zugewiesener_verkaeufer,
    status_verkaeufer: status_verkaeufer,
  });

  const [changed, setChanged] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    const updatedFields = {
        ...fields,
        [name]: updatedValue
    };

    // Überprüfe, ob alle Felder gleich ihren initialen Werten sind
    const allFieldsMatchInitialValues = Object.keys(updatedFields).every(fieldName => {
        return updatedFields[fieldName] === initialFields[fieldName];
    });

    // Überprüfe, ob ein Termin zugewiesen ist und bestätigt ist
    const terminZugewiesenOhneBestaetigung = (updatedFields.terminbestaetigung === "Nein" && updatedFields.zugewiesener_verkaeufer !== "Nicht zugewiesen");

    setChanged(!allFieldsMatchInitialValues && !terminZugewiesenOhneBestaetigung);
    setFields(updatedFields);
};


  useEffect(() => {
    console.log("initialFields", initialFields)
    console.log("changed", changed);
    console.log("changed", fields);
    console.log("verkaeuferList", verkaeuferList);
  });

  const handleUpdate = () => {
      updateTermin(id, fields);
  };

  // Funktion zum Formatieren des Datums
  const dateFormat = (dateString) => {
    const timestamp = new Date(dateString);
    const day = ('0' + timestamp.getDate()).slice(-2);
    const month = ('0' + (timestamp.getMonth() + 1)).slice(-2);
    const year = timestamp.getFullYear();
    const hours = ('0' + timestamp.getHours()).slice(-2);
    const minutes = ('0' + timestamp.getMinutes()).slice(-2);

    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  };



  // Funktion zum Formatieren des Datums
  const dateFormat_wO_time = (dateString) => {
    const timestamp = new Date(dateString);
    const day = ('0' + timestamp.getDate()).slice(-2);
    const month = ('0' + (timestamp.getMonth() + 1)).slice(-2);
    const year = timestamp.getFullYear();

    return `${day}/${month}/${year}`;
  };

  // Funktion zum Zuweisen der Hintergrundfarbe basierend auf dem Status
  const getStatusColor = () => {
    switch (status) {
      case 'Termin':
        return '#42ad00';
      case 'Wiedervorlage':
        return '#ffd404';
      case 'Keine Interesse':
        return '#f44434';
      case 'Nicht Da':
        return '#6a329f';
      default:
        return 'gray';
    }
  };

  // Funktion zum Festlegen der Textfarbe für den Terminstatus
  const getStatusTextColor = () => {
    return terminstatus === 'aktiv' ? '#42ad00' : '#f44434';
  };

  // Funktion zum Festlegen des Texts für das Datum basierend auf dem Status
  const getDateText = () => {
    switch (status) {
      case 'Termin':
        return 'des Termins';
      case 'Wiedervorlage':
        return 'der Wiedervorlage';
      default:
        return '';
    }
  };

  // Zustand für den aktiven Tab
  const [activeTab, setActiveTab] = useState('personal');

  const handleStorniereTermin = () => {
    Swal.fire({
      title: "Sicher, dass du den Termin stornieren möchtest?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja, Termin stornieren!",
      background: "#fcf6f9",
      color: "#222260"
    }).then((result) => {
      if (result.isConfirmed) {
        storniereTermin(id)
        Swal.fire({
          title: "Storniert!",
          text: "Der Termin wurde erfolgreich storniert",
          icon: "success",
          background: "#fcf6f9",
          color: "#222260",
          showConfirmButton: false,
          timer: 1200,
        });
      }
    });
  }

  const handleDeleteTermin = () => {
    Swal.fire({
      title: "Sicher, dass du den Termin endgültig löschen möchtest?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja, Termin löschen!",
      background: "#fcf6f9",
      color: "#222260",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTermin(id)
        closeDetails()
        Swal.fire({
          title: "Gelöscht!",
          text: "Der Termin wurde erfolgreich gelöscht",
          icon: "success",
          background: "#fcf6f9",
          color: "#222260",
          showConfirmButton: false,
          timer: 1200,
        });
      }
    });
  }

  const [selectedTime, setSelectedTime] = useState(uhrzeit || '');
  const [hourValid, setHourValid] = useState(true);
  const [minuteValid, setMinuteValid] = useState(true);

  useEffect(() => {
    if (uhrzeit) {
      setSelectedTime(uhrzeit);
    }
  }, [uhrzeit]);
  
  useEffect(() => {
    console.log('Selected time has changed:', selectedTime);
    console.log('Selected time has changed:', jahresverbrauch);
  }, [selectedTime]);

  
  // Format the date to yyyy-mm-dd
  const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
  };
  
  const handleEditTerminWrapper = async () => {
    await handleEditTermin(strasse, hausnummer, plz, ort, status, vorname, nachname, email, phone_1, phone_2, datum, uhrzeit, jahresverbrauch, personen_count, mtl_abschlag, art_flaeche, dacheindeckung, ausrichtung_flaeche, kommentar, id, updateTermin, formatDate, setMinuteValid, setHourValid, selectedTime, setSelectedTime, MySwal, Swal, ReactDOM);
    };


  const isKommentarPresent = (kommentar) => {
    return kommentar && kommentar.trim().length > 0; // Überprüfen, ob der Kommentar vorhanden und nicht leer ist
  };

  const handleChangeDropdownDetails = () => {
    Swal.fire({
      title: "Sicher, dass du die Änderungen speichern möchtest?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja, Änderungen speichern!",
      background: "#fcf6f9",
      color: "#222260",
    }).then((result) => {
      if (result.isConfirmed) {
        handleUpdate()
        closeDetails()
        Swal.fire({
          title: "Änderungen gespeichert!",
          icon: "success",
          background: "#fcf6f9",
          color: "#222260",
          showConfirmButton: false,
          timer: 1200,
        });
      }
    });
  }


  return (
    <motion.div
      className="details-card"
      initial={{ opacity: 0, scale: 0.9, y: -50 }} // Startzustand
      animate={{ opacity: 1, scale: 1, y: 0 }} // Zustand während der Anzeige
      exit={{ opacity: 0, scale: 0.9, y: -50 }} // Zustand beim Schließen
      transition={{ duration: 0.3, ease: "easeInOut" }} // Übergangseffekt
    >
      <BigCardStyled>
        <div className="close-icon" onClick={closeDetails}>
          <FaTimes size={24} color="#000" />
        </div>
        <div className="details-card-content-wrapper">
        <div className="details-card-content">
          <div className="details-card-infos">
          <Badge style={{ backgroundColor: getStatusColor() }}>{status}</Badge>
          <Adresse>Termintag & Uhrzeit: <BoldText>{dateFormat_wO_time(datum) || 'k.A.'}</BoldText> <BoldText>{uhrzeit || 'k.A.'}</BoldText></Adresse>
          <Besuchsdatum>Adresse: <BoldText>{plz}, {ort}, {strasse} {hausnummer}</BoldText></Besuchsdatum>
          <Besuchsdatum>Datum des Besuchs: <BoldText>{dateFormat(createdAt)}</BoldText></Besuchsdatum>
          <Besuchsdatum><BoldText>Terminstatus: <span style={{ color: getStatusTextColor() }}>{terminstatus}</span></BoldText></Besuchsdatum>
          <div className="container-1">
            <div className="dropdown-container">
            <div className="selects input-control">
                  <select
                      required
                      value={fields.terminbestaetigung}
                      name="terminbestaetigung"
                      style={{ width: '200px' }}
                      id="terminbestaetigung"
                      onChange={handleChange}
                      disabled={status !== "Termin"}
                    >
                      <option value="Ja">Ja</option>
                      <option value="Nein">Nein</option>
                    </select>
            </div>
            <div className="selects input-control">
              <select 
                required 
                value={fields.zugewiesener_verkaeufer} 
                name="zugewiesener_verkaeufer" 
                style={{ width: '200px' }} 
                id="zugewiesener_verkaeufer" 
                onChange={handleChange}
                disabled={fields.terminbestaetigung !== "Ja"}
              >
                <option value="Nicht zugewiesen">Nicht zugewiesen</option>
                {verkaeuferList.map((verkaeufer, index) => (
                  <option key={index} value={verkaeufer}>
                    {verkaeufer}
                  </option>
                ))}
              </select>
            </div>
            <div className="selects input-control">
                  <input required value={fields.status_verkaeufer} name="status_verkaeufer" style={{ width: '200px' }} id="status_verkaeufer" onChange={handleChange} disabled>
                  </input>
            </div>
            {changed ?
                    <ButtonStyled>
                        <Button
                            className="save-and-submit-btn"
                            name={'Änderungen speichern'}
                            bPad={'.5rem .6rem'}
                            bRad={'30px'}
                            bg={'var(--color-accent'}
                            color={'#fff'}
                            onClick={handleChangeDropdownDetails}
                        />
                    </ButtonStyled>
                    : null
                }
            </div>
          <div className="buttons-container">
          <Button
            className="edit-termin-btn"
            name={'Termin bearbeiten'}
            bPad={'.5rem .8rem'}
            bRad={'30px'}
            bg={'var(--color-accent'}
            color={'#fff'}
            onClick={handleEditTerminWrapper}
          />
          <Button
            className="storniere-termin-btn"
            name={'Termin stornieren'}
            bPad={'.5rem .8rem'}
            bRad={'30px'}
            bg={'var(--color-accent'}
            color={'#fff'}
            onClick={handleStorniereTermin}
          />
          <Button
            className="delete-termin-btn"
            name={'Termin löschen'}
            bPad={'.5rem .8rem'}
            bRad={'30px'}
            bg={'var(--color-accent'}
            color={'#fff'}
            onClick={handleDeleteTermin}
          />
          
          <div className="pdf-btn">
            {/* PDF Download Button */}
            <PDFDownloadLink document={<TerminPDF jahresverbrauch={jahresverbrauch} ausrichtung_flaeche={ausrichtung_flaeche} art_flaeche={art_flaeche} personen_count={personen_count} dacheindeckung={dacheindeckung} mtl_abschlag={mtl_abschlag} vorname={vorname} nachname={nachname} status={status} email={email} phone_1={phone_1} phone_2={phone_2} werber={werber} plz={plz} ort={ort} strasse={strasse} hausnummer={hausnummer} uhrzeit={uhrzeit} datum={datum} kommentar={kommentar} />} fileName={`Termin_${nachname}_${plz}_${ort}_${strasse}_${hausnummer}${dateFormat_wO_time(datum)}.pdf`}>
              {({ blob, url, loading, error }) => (
                <Button
                  icon={pdf}
                  bPad={'1rem'}
                  bRad={'50%'}
                  bg={'var(--color-accent'}
                  color={'#fff'}
                  iColor={'#fff'}
                  hColor={'var(--color-green)'}
                />
              )}
            </PDFDownloadLink>
          </div>
        </div>
        </div>
          </div>
          <div className="tab-content">
            <div className="text">
              <TabContainer>
                {/* Tab "Persönliche Daten" */}
                <Tab
                  active={activeTab === 'personal'}
                  onClick={() => setActiveTab('personal')}
                >
                  Persönliche Daten
                </Tab>
                {/* Tab "Verbrauchsdaten" */}
                <Tab
                  active={activeTab === 'consumption'}
                  onClick={() => setActiveTab('consumption')}
                >
                  Verbrauchsdaten
                </Tab>
              </TabContainer>
              <div className="tab-content">
                <div className="text">
                  <Table style={{ display: activeTab === 'personal' ? 'table' : 'none' }}>
                    <TableRow>
                      <TableCell>Werber</TableCell>
                      <TableCell>{werber || 'k.A.'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>{nachname || 'k.A.'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Vorname</TableCell>
                      <TableCell>{vorname || 'k.A.'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>E-Mail</TableCell>
                      <TableCell>{email || 'k.A.'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Festnetz</TableCell>
                      <TableCell>{phone_1 || 'k.A.'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Mobil</TableCell>
                      <TableCell>{phone_2 || 'k.A.'}</TableCell>
                    </TableRow>
                  </Table>
                  </div>
                  </div>
                  <Table style={{ display: activeTab === 'consumption' ? 'table' : 'none' }}>
                    <TableRow>
                      <TableCell>Jahresverbrauch</TableCell>
                      <TableCell>{jahresverbrauch || 'k.A.'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Personen im Haushalt</TableCell>
                      <TableCell>{personen_count || 'k.A.'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Mtl. Abschlag €</TableCell>
                      <TableCell>{mtl_abschlag || 'k.A.'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Art der Fläche</TableCell>
                      <TableCell>{art_flaeche || 'k.A.'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Dacheindeckung</TableCell>
                      <TableCell>{dacheindeckung || 'k.A.'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Ausrichtung der Fläche</TableCell>
                      <TableCell>{ausrichtung_flaeche || 'k.A.'}</TableCell>
                    </TableRow>
                  </Table>
                  </div>
                  </div>
                  </div>
                  {isKommentarPresent(kommentar) && (
                    <KommentarContainer>
                      <Kommentarzeile>
                        Kommentar:
                        <br />
                        <BoldText>{kommentar}</BoldText>
                      </Kommentarzeile>
                    </KommentarContainer>
                  )}
                  </div>
        
      </BigCardStyled>
    </motion.div>
  );
}


const SmallCardStyled = styled.div`
    background: #FCF6F9;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    color: #222260;
    .icon{
        width: 80px;
        height: 80px;
        border-radius: 20px;
        background: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #FFFFFF;
        i{
            font-size: 2.6rem;
        }
    }

    .content{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: .2rem;
        h5{
            font-size: 1.3rem;
            padding-left: 2rem;
            position: relative;
            // hier könnte badge gerendert werden
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: .8rem;
                height: .8rem;
                border-radius: 50%;
                background: ${props => {
    switch (props.terminstatus) {
      case 'aktiv':
        return '#42ad00'; // Grüne Farbe für "aktiv"
      case 'storniert':
        return '#f44434'; // Rote Farbe für "storniert"
      default:
        return 'transparent'; // Standardfarbe
    }
  }};
            }
        }

        .inner-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .text{
                display: flex;
                align-items: center;
                gap: 1.5rem;
                p{
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    color: var(--primary-color);
                    opacity: 0.8;
                }
            }
        }
    }

    /* Hover-Verhalten für SmallCard beibehalten, außer wenn DetailsCard geöffnet ist */
    &:not(.details-card-open):hover {
        background-color: rgba(252, 252, 252, 0.9);
        cursor: pointer;
    }
`;

const StyledCheckbox = styled.input`
  width: 24px;
  height: 24px;
`;

const Message = styled.div`
  color: red;
  margin-top: 10px;
`;

// Styled-Component für die Tab-Navigation
const TabContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
  margin-left: auto;
`;

// Styled-Component für jeden Tab
const Tab = styled.div`
    border: 2px solid white;
    background: transparent;
    margin: 5px;
    margin-left: auto;
    border-radius: 10px;
    padding: 10px 120px;
    cursor: pointer;
    font-size: 16px;

    ${({ active }) =>
    active &&
    `
    background-color: var(--color-accent) !important;
    color: #fff;
    font-weight: bold;
    border: 2px solid var(--color-accent);
  `}
`;

// Styled-Component für den Inhalt der Tabs
const TabContent = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
  width: calc(50% - 1rem);
  justify-content: center;
`;

const Badge = styled.div`
  border-radius: 1rem;
  padding: 0.3rem 0.3rem;
  max-width: 10rem;
  color: #fff;
  font-weight: bold;
  background-color: var(--color-gray);
  display: flex;
  align-items: center;
  justify-content: center;
`;


const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow:hidden;
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: white;
  }
  &:not(:last-child) {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  padding: 12px;
`;

const BigCardStyled = styled.div`
  display:flex;
  flex-direction:column;
  position: relative;
  background: #fcf6f9;
  border: 2px solid #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;
  gap: 1rem;
  width: 100%;
  color: #222260;
  height: 65vh;
  z-index: 9;
  overflow: auto;

  .details-card-content-wrapper{
    display:flex;
    flex-direction:column;

  }

  .details-card-content{
    display:flex;
    flex-direction:row;
    justify-content:space-around;
  }

  .details-card-infos{
    margin-top: 1rem;
  }

  .close-icon {
        cursor: pointer;
    }
    
  .close-icon:hover svg {
        transform: scale(1.2); /* Make it larger on hover */
        transition: transform 0.2s ease; /* Add smooth transition */
        fill: red; /* Change color to red on hover */
    }

  .buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: top;
      align-items: center;
      gap: .8rem;

      button {
        &:hover {
          background: var(--color-green) !important;
        }
      }
    }
  
    .dropdown-container{
      display: flex;
      flex-direction: column;
      justify-content: top;
      align-items: center;
      gap: .8rem;

      button {
        &:hover {
          background: var(--color-green) !important;
        }
      }
    }

    .container-1{
      display: flex;
      flex-direction: row;
      gap: 2rem;
      justify-content: center;
    }
`;

const ButtonStyled = styled.div`
    position: absolute;
    margin-top: 11.5rem;
`;

const CloseIcon = styled.div`
  position: sticky;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  color: #aaa;
`;

const Adresse = styled.p`
  margin-top: 20px;
  font-size: 1.2rem;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const Besuchsdatum = styled.p`
  margin-top: 5px;
`;

const KommentarContainer = styled.div`
  text-align: center;
`;

const Kommentarzeile = styled.p`
  font-size: 1.2rem;
  word-break: break-all;
  max-width: 1000px; /* oder die gewünschte Breite */
  margin: 0 auto; /* um den Text horizontal zu zentrieren */
`;

export default TerminItem;


