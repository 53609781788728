import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { InnerLayout } from '../../styles/Layouts';
import { useGlobalContext } from '../../context/globalContext';
import TerminItem from '../Bestätiger/TerminItem';
import Button from '../../Components/Button/Button';
import { csv, zuweisen } from '../../utils/Icons';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

export default function Termine() {
    const [value, setValue] = useState(0);
    const { getAllTerminstaten, terminstatenList, getAllActiveWerber, werberList, getAllActiveVerkaeufer, verkaeuferList, getAllTermine, AllTermine, storniereTermin, deleteTermin, updateTermin, updateSelectedTermine } = useGlobalContext();

    

    // Funktion zum Codieren von CSV-Feldern
const encodeCSVField = (value) => {
  // Überprüfen, ob der Wert nicht undefined oder null ist
  if (value !== undefined && value !== null) {
    // Feldwert in CSV-Format codieren
    return '"' + value.toString().replace(/"/g, '""') + '"';
  } else {
    // Leerer String zurückgeben, wenn der Wert undefined oder null ist
    return '""';
  }
};

    // Function to export data as CSV
    const exportCSV = () => {
      // Combine all filtered data into one array
      const filteredData = AllTermine.filter((termin) => {
        const searchLowerCase = selectedWerber.toLowerCase();
        return searchLowerCase === '' || termin.werber.toLowerCase().includes(searchLowerCase);
      })
      .filter((termin) => {
        const searchLowerCase = searchVorname.toLowerCase();
        const searchLowerCaseNachname = searchVorname.toLowerCase(); // Nachname hinzugefügt
        return searchLowerCase === '' || termin.vorname.toLowerCase().includes(searchLowerCase) || termin.nachname.toLowerCase().includes(searchLowerCaseNachname); // Nachname durchsuchen
      })
      .filter((termin) => {
        // Hier das Datum um einen Tag erhöhen und vergleichen
        const dayAfter = new Date(termin.datum);
        dayAfter.setDate(dayAfter.getDate() + 1);
        return startDate === '' || dayAfter > new Date(startDate);
      })
      .filter((termin) => {
        // Hier das Datum um einen Tag reduzieren und vergleichen
        const dayBefore = new Date(termin.datum);
        dayBefore.setDate(dayBefore.getDate() - 1);
        return endDate === '' || dayBefore < new Date(endDate);
      })
      .filter((termin) => {
        // Hier das Datum um einen Tag erhöhen und vergleichen
        const visit_dayAfter = new Date(termin.createdAt);
        visit_dayAfter.setDate(visit_dayAfter.getDate());
        return visit_startDate === '' || visit_dayAfter > new Date(visit_startDate);
      })
      .filter((termin) => {
        // Hier das Datum um einen Tag reduzieren und vergleichen
        const visit_dayBefore = new Date(termin.createdAt);
        visit_dayBefore.setDate(visit_dayBefore.getDate() - 1);
        return visit_endDate === '' || visit_dayBefore < new Date(visit_endDate);
      })
      .filter((termin) => {
        const searchLowerCase = selectedTerminstatus.toLowerCase();
        return searchLowerCase === '' || termin.terminstatus.toLowerCase().includes(searchLowerCase);
      })
      .filter((termin) => {
        const searchLowerCase = selectedTerminbestaetigung.toLowerCase();
        return searchLowerCase === '' || termin.terminbestaetigung.toLowerCase().includes(searchLowerCase);
      })
      .filter((termin) => {
        const searchLowerCase = selectedStatusVerkaeufer.toLowerCase();
        return searchLowerCase === '' || termin.status_verkaeufer.toLowerCase().includes(searchLowerCase);
      })
      .filter((termin) => {
        const searchLowerCase = selectedFilters.map(filter => filter.toLowerCase());
        return searchLowerCase.length === 0 || searchLowerCase.includes(termin.status.toLowerCase());
      });

        
      // Create a CSV string
      let csvContent = "ID,PLZ,Ort,Strasse,Hausnummer,Werber,Vorname,Nachname,Datum,Uhrzeit,Status,Kommentar,Terminstatus,Jahresverbrauch,Personen im Haushalt,Mtl. Abschlag €,Art der Fläche,Dacheindeckung,Ausrichtung der Fläche, Verkäufer, Status_Verkäufer\n";
    
      // Loop through each filtered data item and append to CSV
      filteredData.forEach((item) => {
        // Append encoded values to CSV content
        csvContent += `${encodeCSVField(item._id)},${encodeCSVField(item.plz)},${encodeCSVField(item.ort)},${encodeCSVField(item.strasse)},${encodeCSVField(item.hausnummer)},${encodeCSVField(item.werber)},${encodeCSVField(item.vorname)},${encodeCSVField(item.nachname)},${encodeCSVField(item.datum)},${encodeCSVField(item.uhrzeit)},${encodeCSVField(item.status)},${encodeCSVField(item.kommentar)},${encodeCSVField(item.terminstatus)},${encodeCSVField(item.jahresverbrauch)},${encodeCSVField(item.personen_count)},${encodeCSVField(item.mtl_abschlag)},${encodeCSVField(item.art_flaeche)},${encodeCSVField(item.dacheindeckung)},${encodeCSVField(item.ausrichtung_flaeche)},${encodeCSVField(item.zugewiesener_verkaeufer)},${encodeCSVField(item.status_verkaeufer)}\n`;
      });
    
      // Convert CSV content to Uint8Array
      const csvData = new Uint8Array(csvContent.length);
      for (let i = 0; i < csvContent.length; i++) {
        csvData[i] = csvContent.charCodeAt(i);
      }
    
      // Create Blob with CSV data
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    
      // Create a download link
      const link = document.createElement("a");
      link.setAttribute("href", URL.createObjectURL(blob));
      link.setAttribute("download", "terminexport.csv");
      document.body.appendChild(link);
    
      // Trigger download
      link.click();
    };

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const [searchVorname, setSearchVorname] = useState('');
    const [startDate, setStartDate] = useState(getTodayDate());
    const [endDate, setEndDate] = useState(getTodayDate());
    const [selectedWerber, setSelectedWerber] = useState('');
    const [selectedVerkaeufer, setSelectedVerkaeufer] = useState('');
    const [selectedTerminstatus, setSelectedTerminstatus] = useState('');
    const [selectedTerminbestaetigung, setSelectedTerminbestaetigung] = useState('');
    const [selectedStatusVerkaeufer, setSelectedStatusVerkaeufer] = useState('');
    const [visit_startDate, setvisitStartDate] = useState('');
    const [visit_endDate, setvisitEndDate] = useState('');

    // Funktion, um den ausgewählten Werber zu setzen
    const handleWerberChange = (event) => {
        setSelectedWerber(event.target.value);
    };

    // Funktion, um den ausgewählten Verkäufer zu setzen
    const handleVerkaeuferChange = (event) => {
      setSelectedVerkaeufer(event.target.value);
    };

    const handleTerminstatusChange = (event) => {
      setSelectedTerminstatus(event.target.value);
    };

    const handleTerminbestaetigungChange = (event) => {
      setSelectedTerminbestaetigung(event.target.value);
    };

    const handleStatusVerkaeuferChange = (event) => {
      setSelectedStatusVerkaeufer(event.target.value);
    };


    const [selectedFilters, setSelectedFilters] = useState([]);
    let filters = ["Termin", "Wiedervorlage", "Nicht da", "Keine Interesse"];

    const handleFilterButtonClick = (selectedCategory) => {
      if (selectedFilters.includes(selectedCategory)) {
        setSelectedFilters(prevFilters => prevFilters.filter(el => el !== selectedCategory));
      } else {
        // Überprüfen, ob ein anderer Filter bereits ausgewählt ist
        const otherSelectedFilters = selectedFilters.filter(filter => filter !== "alle Kategorien");
        if (otherSelectedFilters.length > 0) {
          setSelectedFilters(prevFilters => [...prevFilters, selectedCategory]);
        } else {
          setSelectedFilters([selectedCategory]);
        }
      }
    };

    const [activeVerkaeufer, setActiveVerkaeufer] = useState([]);

    useEffect(() => {
      getAllActiveWerber();
      getAllTerminstaten();
      getAllActiveVerkaeufer();
      getAllTermine(startDate, endDate);
      console.log("activeVerkaeufer", activeVerkaeufer);
      console.log("werberList", werberList);
      console.log("selectedFilters", selectedFilters);
      console.log("startDate", startDate)
    }, [selectedFilters, startDate, endDate]);

    const [filteredTermine, setFilteredTermine] = useState([]);

    useEffect(() => {
      setFilteredTermine(
        AllTermine
          .filter((termin) => {
            const searchLowerCase = selectedWerber.toLowerCase();
            return searchLowerCase === '' || termin.werber.toLowerCase().includes(searchLowerCase);
          })
          .filter((termin) => {
            const searchLowerCase = searchVorname.toLowerCase();
            const searchLowerCaseNachname = searchVorname.toLowerCase(); // Nachname hinzugefügt
            return searchLowerCase === '' || termin.vorname.toLowerCase().includes(searchLowerCase) || termin.nachname.toLowerCase().includes(searchLowerCaseNachname); // Nachname durchsuchen
          })
          .filter((termin) => {
            // Hier das Datum um einen Tag erhöhen und vergleichen
            const dayAfter = new Date(termin.datum);
            dayAfter.setDate(dayAfter.getDate() + 1);
            return startDate === '' || dayAfter > new Date(startDate);
          })
          .filter((termin) => {
            // Hier das Datum um einen Tag reduzieren und vergleichen
            const dayBefore = new Date(termin.datum);
            dayBefore.setDate(dayBefore.getDate() - 1);
            return endDate === '' || dayBefore < new Date(endDate);
          })
          .filter((termin) => {
            // Hier das Datum um einen Tag erhöhen und vergleichen
            const visit_dayAfter = new Date(termin.createdAt);
            visit_dayAfter.setDate(visit_dayAfter.getDate());
            return visit_startDate === '' || visit_dayAfter > new Date(visit_startDate);
          })
          .filter((termin) => {
            // Hier das Datum um einen Tag reduzieren und vergleichen
            const visit_dayBefore = new Date(termin.createdAt);
            visit_dayBefore.setDate(visit_dayBefore.getDate() - 1);
            return visit_endDate === '' || visit_dayBefore < new Date(visit_endDate);
          })
          .filter((termin) => {
            const searchLowerCase = selectedTerminstatus.toLowerCase();
            return searchLowerCase === '' || termin.terminstatus.toLowerCase().includes(searchLowerCase);
          })
          .filter((termin) => {
            const searchLowerCase = selectedTerminbestaetigung.toLowerCase();
            return searchLowerCase === '' || termin.terminbestaetigung.toLowerCase().includes(searchLowerCase);
          })
          .filter((termin) => {
            const searchLowerCase = selectedStatusVerkaeufer.toLowerCase();
            return searchLowerCase === '' || termin.status_verkaeufer.toLowerCase().includes(searchLowerCase);
          })
          .filter((termin) => {
            const searchLowerCase = selectedFilters.map(filter => filter.toLowerCase());
            return searchLowerCase.length === 0 || searchLowerCase.includes(termin.status.toLowerCase());
          })
      );
    }, [AllTermine, selectedWerber, searchVorname, startDate, endDate, visit_startDate, visit_endDate, selectedTerminstatus, selectedTerminbestaetigung, selectedStatusVerkaeufer, selectedFilters]);

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const [selectedIds, setSelectedIds] = useState(new Set());

  const handleCheckboxChange = (id, isChecked) => {
    const newSelectedIds = new Set(selectedIds);
    if (isChecked) {
      newSelectedIds.add(id);
    } else {
      newSelectedIds.delete(id);
    }
    setSelectedIds(newSelectedIds);
  };

  useEffect(() => {
    if (selectedIds.size === 0) {
      setSelectedVerkaeufer(""); // Reset selectedVerkaeufer when selectedIds becomes empty
    }
    console.log("selectedIds", selectedIds);
    console.log("selectedVerkaeufer", selectedVerkaeufer);
  }, [selectedIds,selectedVerkaeufer]);

  const handleTerminZuweisenDropdown = () => {
    Swal.fire({
      title: "Sicher, dass du diesem Verkäufer alle ausgewählten Termine zuweisen möchtest?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Nein",
      confirmButtonText: "Ja, Zuweisen!",
      background: "#fcf6f9",
      color: "#222260"
    }).then((result) => {
      if (result.isConfirmed) {
        updateSelectedTermine(selectedIds, selectedVerkaeufer)
        Swal.fire({
          title: "Zugewiesen!",
          text: "Zuweisung erfolgreich!",
          icon: "success",
          background: "#fcf6f9",
          color: "#222260",
          showConfirmButton: false,
          timer: 1200,
        });
      }
    });
  }

  const disableDropdownCondition = visit_startDate === '' || visit_endDate === '';

    return (
      <TermineStyled>
          <InnerLayout>
          <Box sx={{ width: '100%'}}>
            <div className='sticky-header'>
                <h1>Terminübersicht</h1>
                <div className="export-btn">
                <Button 
                    name={'Export CSV'}
                    icon={csv}
                    bPad={'.8rem 1.6rem'}
                    bRad={'30px'}
                    bg={'var(--color-accent'}
                    color={'#fff'}
                    onClick={exportCSV}
                />
          </div>
          
        <div className="search-inputs">
        <Form>
            <InputGroup className='selects input-control'>
            <select id="werberDropdown" onChange={handleWerberChange} value={selectedWerber || ''} style={{ width: '150px' }}>
                        <option value="">Alle Werber</option>
                        {werberList.map((werber, index) => (
                            <option key={index} value={werber}>
                                {werber}
                            </option>
                        ))}
                    </select>
            </InputGroup>
        </Form>
        <Form>
            <InputGroup className='input-control'>
                <Form.Control
                    onChange={(e) => setSearchVorname(e.target.value)}
                    placeholder='Suche Kunde'
                    style={{ width: '150px' }}
                />
            </InputGroup>
        </Form>
        <Form>
        <InputGroup className='input-control'>
            <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => {
                    if (e.target.value) { // Überprüfung, ob ein Datum ausgewählt wurde
                        const selectedDate = new Date(e.target.value);
                        selectedDate.setDate(selectedDate.getDate()); // Einen Tag abziehen
                        const formattedDate = selectedDate.toISOString().split('T')[0]; // Formatierung in "YYYY-MM-DD"
                        setStartDate(formattedDate);
                    } else {
                        setStartDate(''); // Wenn kein Datum ausgewählt ist, leeren String setzen
                    }
                }}
                disabled={disableDropdownCondition}
                placeholder='Startdatum eingeben'
            />
        </InputGroup>
        </Form>
        <Form>
            <InputGroup className='input-control'>
                <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      if (e.target.value) {
                          const selectedDate = new Date(e.target.value);
                          selectedDate.setDate(selectedDate.getDate()); // Einen Tag hinzufügen
                          const formattedDate = selectedDate.toISOString().split('T')[0];
                          setEndDate(formattedDate);
                      } else {
                          setEndDate('');
                      }
                    }}
                    disabled={disableDropdownCondition}
                    placeholder='Enddatum eingeben'
                />
            </InputGroup>
        </Form>
        <Form>
            <InputGroup className='selects input-control'>
            <select id="terminstatusDropdown" onChange={handleTerminstatusChange} value={selectedTerminstatus || ''} style={{ width: '150px' }}>
                        <option value="">Staten</option>
                        {terminstatenList.map((terminstatus, index) => (
                            <option key={index} value={terminstatus}>
                                {terminstatus}
                            </option>
                        ))}
                    </select>
            </InputGroup>
        </Form>
        <Form>
            <InputGroup className='selects input-control'>
            <select id="terminbestaetigungDropdown" onChange={handleTerminbestaetigungChange} value={selectedTerminbestaetigung || ''} style={{ width: '150px' }}>
                        <option value="">Alle</option>
                        <option value="Ja">Bestätigt</option>
                        <option value="Nein">Nicht bestätigt</option>
                    </select>
            </InputGroup>
        </Form>
        <Form>
            <InputGroup className='selects input-control'>
            <select id="StatusVerkaeuferDropdown" onChange={handleStatusVerkaeuferChange} value={selectedStatusVerkaeufer || ''} style={{ width: '150px' }}>
                        <option value="">Alle</option>
                        <option value="Kein Status">Kein Status</option>
                        <option value="Abschluss (1)">Abschluss</option>
                        <option value="Konnte nicht verkaufen (2)">Konnte nicht verkaufen</option>
                        <option value="Angebot (3)">Angebot</option>
                        <option value="Überleger (4)">Überleger</option>
                        <option value="Nicht da (5)">Nicht da</option>
                        <option value="Keine Interesse (6)">Keine Interesse</option>
                        <option value="Technisch nicht möglich (7)">Technisch nicht möglich</option>
                        <option value="Storniert">Storniert</option>
                    </select>
            </InputGroup>
        </Form>
        <Form>
        <InputGroup className='input-control'>
            <Form.Control
                type="date"
                value={visit_startDate}
                onChange={(e) => {
                    if (e.target.value) { // Überprüfung, ob ein Datum ausgewählt wurde
                        const selectedDate = new Date(e.target.value);
                        selectedDate.setDate(selectedDate.getDate()); // Einen Tag abziehen
                        const formattedDate = selectedDate.toISOString().split('T')[0]; // Formatierung in "YYYY-MM-DD"
                        setvisitStartDate(formattedDate);
                    } else {
                        setvisitStartDate(''); // Wenn kein Datum ausgewählt ist, leeren String setzen
                    }
                }}
                placeholder='Startdatum eingeben'
            />
        </InputGroup>
        </Form>
        <Form>
        <InputGroup className='input-control'>
                <Form.Control
                    type="date"
                    value={visit_endDate}
                    onChange={(e) => {
                      if (e.target.value) {
                          const selectedDate = new Date(e.target.value);
                          selectedDate.setDate(selectedDate.getDate()); // Einen Tag hinzufügen
                          const formattedDate = selectedDate.toISOString().split('T')[0];
                          setvisitEndDate(formattedDate);
                      } else {
                          setvisitEndDate('');
                      }
                    }}
                    placeholder='Enddatum eingeben'
                />
            </InputGroup>
        </Form>
    </div>
    <div className="buttons-container">
        {filters.map((category, idx) => (
          <button
            onClick={() => handleFilterButtonClick(category)}
            className={`button ${
              selectedFilters?.includes(category) ? "active" : ""
            }`}
            key={`filters-${idx}`}
          >
            {category}
          </button>
        ))}
      </div>
      </div>
      <div className="anzahl-and-zuweisen-row">
      <h3>Anzahl: {filteredTermine.length}</h3>
      {selectedIds.size > 0 && (
        <Form>
        <InputGroup className='selects input-control'>
        <select id="multipleVerkaeuferZuweisenDropdown" onChange={handleVerkaeuferChange} value={selectedVerkaeufer || ''}>
                    <option value="" disabled>-- Wähle einen Verkäufer --</option>
                    {verkaeuferList.map((verkaeufer, index) => (
                        <option key={index} value={verkaeufer}>
                            {verkaeufer}
                        </option>
                    ))}
                </select>
        </InputGroup>
    </Form>
        )}
        {selectedVerkaeufer && (
          <div className="zuweisen-btn">
            <Button
              name={'Zuweisen'}
              icon={zuweisen}
              bPad={'.8rem 1.6rem'}
              bRad={'30px'}
              bg={'var(--color-accent'}
              color={'#fff'}
              onClick={handleTerminZuweisenDropdown}
            />
          </div>
        )}
      </div>
      <div className="income-content">
        <div className="incomes">
          {filteredTermine.map((termin) => {
            const {_id, adresse, strasse, hausnummer, plz, ort, vorname, nachname, email, phone_1, phone_2, werber,  datum, createdAt, uhrzeit, status, kommentar, terminstatus, jahresverbrauch, personen_count, mtl_abschlag, art_flaeche, dacheindeckung, ausrichtung_flaeche, terminbestaetigung, zugewiesener_verkaeufer, status_verkaeufer} = termin;
            return <TerminItem
              key={_id}
              id={_id} 
              adresse={adresse}
              strasse={strasse}
              hausnummer={hausnummer}
              plz={plz}
              ort={ort}
              vorname={vorname}
              nachname={nachname}
              email={email}
              phone_1={phone_1}
              phone_2={phone_2} 
              datum={datum}
              createdAt={createdAt} 
              uhrzeit={uhrzeit} 
              status={status}
              kommentar={kommentar} 
              terminstatus={terminstatus}
              jahresverbrauch={jahresverbrauch}
              personen_count={personen_count}
              mtl_abschlag={mtl_abschlag}
              art_flaeche={art_flaeche}
              dacheindeckung={dacheindeckung}
              ausrichtung_flaeche={ausrichtung_flaeche}
              terminbestaetigung={terminbestaetigung}
              zugewiesener_verkaeufer={zugewiesener_verkaeufer}
              status_verkaeufer={status_verkaeufer}
              werber={werber}
              verkaeuferList={verkaeuferList}
              indicatorColor="var(--color-green)"
              storniereTermin={storniereTermin}
              deleteTermin = {deleteTermin}
              updateTermin = {updateTermin}
              isChecked={selectedIds.has(_id)}
              onCheckboxChange={handleCheckboxChange}
            />
          })}
        </div>
      </div>
       
      </Box>
      </InnerLayout>
      </TermineStyled>
    );
  }
  
  const TermineStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  input, textarea, select{
    font-family: inherit;
    font-size: inherit;
    outline: none;
    border: none;
    padding: .5rem 1rem;
    border-radius: 5px;
    border: 2px solid #fff;
    background: transparent;
    resize: none;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    color: rgba(34, 34, 96, 0.9);
    &::placeholder{
        color: rgba(34, 34, 96, 0.4);
    }
}
.input-control{
    input{
        width: 100%;
    }
}

.selects{
    display: flex;
    justify-content: flex-end;
    select{
        color: rgba(34, 34, 96, 0.4);
        &:focus, &:active{
            color: rgba(34, 34, 96, 1);
        }
    }
}

    .income-content {
        margin-top: 20px;
        display: flex;
        gap: 2rem;
       
        max-height: calc(100vh - 50vh);
        overflow-y: auto;
        @media (max-width: 768px) {
          max-height: calc(100vh - 80vh);
          margin-top: 15px;
        }

        .incomes {
            flex: 1;
        }
    }

    .export-btn {
      position: absolute;
      top: -1rem;
      right: -0.5rem;
      z-index: 1;
  
      button {
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
  
        &:hover {
          background: var(--color-green) !important;
        }
      }
    }

    .such-btn {
      margin-top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      
      button {
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
      
        &:hover {
          background: var(--color-green) !important;
        }
      }
    }
    
    /* Stile für die Suchfelder */
    .search-inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;

        .my-3 {
            flex: 1; /* Die Suchfelder sollen gleichmäßig wachsen */
        }
    }

    .buttons-container {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    
    .button {
      border: 2px solid white;
      background: transparent;
      margin: 5px;
      border-radius: 10px;
      padding: 10px 20px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      font-size: 16px;
    }
    
    .active {
      background-color: var(--color-accent) !important;
      border: 2px solid var(--color-accent);
      color: #fff;
    }
    .sticky-header {
      position: sticky;
      top: 2rem;
      z-index:999999;
    }

    .anzahl-and-zuweisen-row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 1rem;

    }
    
`;
  