import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../context/globalContext';
import { InnerLayout } from '../../styles/Layouts';
import Form from './InputForm';

function WerberListe() {
    const { getLatestEntryStrasse, LatestEntryStrasse } = useGlobalContext();

    useEffect(() => {
        getLatestEntryStrasse(JSON.parse(localStorage.getItem('benutzer')));
    }, []);

    return (
        <WerberListeStyled>
            <InnerLayout>
            <h1 style={{ marginBottom: '1rem', textAlign: "center" }}>Werberliste</h1>
                <div className="income-content">
                    <div className="form-container">
                        {/* Übergebe LatestEntryStrasse an das Formular */}
                        <Form latestEntryStrasse={LatestEntryStrasse} />
                    </div>
                </div>
            </InnerLayout>
        </WerberListeStyled>
    );
}

const WerberListeStyled = styled.div`
    display: flex;
    overflow: auto;
    .total-income{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FCF6F9;
        border: 2px solid #FFFFFF;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        padding: 1rem;
        margin: 1rem 0;
        font-size: 2rem;
        gap: .5rem;
        input{
            font-size: 2.5rem;
            font-weight: 800;
            color: var(--color-green);
        }
    }
    .income-content{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        .incomes{
            flex: 1;
        }
    }
`;

export default WerberListe