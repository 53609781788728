import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
  font-family: inherit;
  font-size: inherit;
  outline: none;
  border: none;
  padding: .5rem 1rem;
  border-radius: 5px;
  border: 2px solid #fff;
  background: transparent;
  resize: none;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
  color: rgba(34, 34, 96, 0.9);
  max-width: 90px;

  &::placeholder {
    color: rgba(34, 34, 96, 0.4);
  }

  /* Define the style for invalid select elements */
  &.invalid {
    border-color: red; /* Change border color for invalid state */
  }
`;

const DisabledInput = styled.input`
  font-family: inherit;
  font-size: inherit;
  outline: none;
  border: none;
  padding: .5rem 1rem;
  border-radius: 5px;
  border: 2px solid #fff;
  background: transparent;
  resize: none;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
  color: rgba(34, 34, 96, 0.9);
  text-align: center;
  pointer-events: none;
  max-width: 80px;
`;

const CustomTimePicker = ({ value, onChange, onMinuteValidChange, onHourValidChange }) => {
  const [hour, setHour] = useState('');
  const [minute] = useState('00');
  const [hourValid, setHourValid] = useState(true);

  useEffect(() => {
    if (value) {
      const [initialHour] = value.split(':');
      setHour(initialHour);
    }
  }, [value]);

  const validateHour = (newHour) => {
    const isValid = newHour !== '';
    setHourValid(isValid);
    onHourValidChange(isValid);
  };

  const handleHourChange = (e) => {
    const newHour = e.target.value;
    setHour(newHour);
    onChange(`${newHour}:00`);
    validateHour(newHour);
  };

  const handleBlur = () => {
    validateHour(hour);
  };

  return (
    <div onBlur={handleBlur}>
      <StyledSelect
        value={hour}
        onChange={handleHourChange}
        className={hourValid ? '' : 'invalid'} // Apply the class based on validity
      >
        <option value="">--</option>
        {[10, 12, 14, 16, 18].map((hour) => (
          <option key={hour} value={String(hour).padStart(2, '0')}>
            {String(hour).padStart(2, '0')}
          </option>
        ))}
      </StyledSelect>
      &nbsp;
      <DisabledInput
        type="text"
        value={minute}
        readOnly
      />
      {(!hourValid) && <span style={{ color: 'red' }}>Ungültige Uhrzeit</span>}
    </div>
  );
};

export default CustomTimePicker;
