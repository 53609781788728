import CustomTimePicker from '../CustomTimePicker/CustomTimePicker';

export const handleEditTermin = async (strasse, hausnummer, plz, ort, status, vorname, nachname, email, phone_1, phone_2, datum, uhrzeit, jahresverbrauch, personen_count, mtl_abschlag, art_flaeche, dacheindeckung, ausrichtung_flaeche, kommentar, id, updateTermin, formatDate, setMinuteValid, setHourValid, selectedTime, setSelectedTime, MySwal, Swal, ReactDOM) => {

    const formattedDatum = formatDate(datum);
    let localSelectedTime = selectedTime; // Local variable to keep track of the selected time

    // Validierungsfunktion für Strasse und Ort
    const validateText = (input) => {
      return input.trim() !== '' && /^[a-zA-ZäöüÄÖÜß\s]+$/.test(input); // Prüft, ob das Eingabefeld nicht leer ist und nur aus Buchstaben, Umlauten, dem scharfen s und Leerzeichen besteht
    };

    // Validierungsfunktion für Name
    const validateName = (input) => {
      return input.trim() === '' || /^[a-zA-ZäöüÄÖÜß]+$/.test(input); // Prüft, ob das Eingabefeld nicht leer ist und nur aus Buchstaben, Umlauten und dem scharfen s besteht
    };

    const validateEmail = (email) => {
      // Ein regulärer Ausdruck zur Überprüfung einer gültigen E-Mail-Adresse
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
      // Überprüfen, ob die E-Mail-Adresse leer ist oder dem regulären Ausdruck entspricht
      return email === '' || emailRegex.test(email);
    };

    const validateHausnummer = (input) => {
      // Wenn die Eingabe nicht leer ist und nur aus Zahlen oder Buchstaben besteht, gibt true zurück
      return input.trim() !== '' && /^[a-zA-Z0-9]+$/.test(input);
    };

    const validatePLZ = (input) => {
      // Wenn die Eingabe nicht leer ist und nur aus Zahlen besteht, gibt true zurück
      return input.trim() !== '' && /^\d+$/.test(input);
    };

    // Validierungsfunktion für Telefonnummern
    const validatePhoneNumber = (input) => {
      // Wenn die Eingabe leer ist oder nur aus Zahlen besteht, gibt true zurück
      return input === '' || /^\d+$/.test(input);
    };

    // Validierungsfunktion für Jahresverbrauch
    const validateJahresverbrauch = (input) => {
      // Wenn die Eingabe leer ist oder nur aus Zahlen besteht, gibt true zurück
      return input === '' || /^\d+$/.test(input);
    };

    // Validierungsfunktion für Jahresverbrauch
    const validatePersonen_im_Haushalt = (input) => {
      // Wenn die Eingabe leer ist oder nur aus Zahlen besteht, gibt true zurück
      return input === '' || /^\d+$/.test(input);
    };

    // Validierungsfunktion für Jahresverbrauch
    const validateMtl_Abschlag = (input) => {
      // Wenn die Eingabe leer ist oder nur aus Zahlen besteht, gibt true zurück
      return input === '' || /^\d+$/.test(input);
    };

    const validateDate = (input) => {
        // Wenn die Eingabe nicht leer ist und dem Format "YYYY-MM-DD" entspricht, gibt true zurück
        return input.trim() !== '';
      };

    const validateUhrzeit = (uhrzeit) => {
      // Definiere einen regulären Ausdruck für das gewünschte Format
      const uhrzeitRegex = /^.{2}:.{2}$/;
    
      // Überprüfe, ob die Uhrzeit dem gewünschten Format entspricht
      return uhrzeitRegex.test(uhrzeit.toString());
    };
  
    const { value: formValues } = await MySwal.fire({
      title: 'Termin bearbeiten',
      html: `
            <style>
            /* CSS-Styling für das Formular */
            .swal2-modal {
            background-color: #fcf6f9;
            }

            .swal2-input {
            color: #232360;
            background-color: white;
            font-family: inherit;
            font-size: inherit;
            outline: none;
            border: none;
            padding: .5rem 1rem;
            border-radius: 5px;
            border: 2px solid #fff;
            background: transparent;
            resize: none;
            box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
            color: rgba(34, 34, 96, 0.9);
            &::placeholder{
                color: rgba(34, 34, 96, 0.4);
            }
            }

            /* Styling für die Auswahlfelder */
            .swal2-select {
            margin-top: 20px;
            color: #232360;
            background-color: white;
            font-family: inherit;
            font-size: inherit;
            outline: none;
            border: none;
            padding: .5rem 1rem;
            border-radius: 5px;
            border: 2px solid #fff;
            background: transparent;
            resize: none;
            box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
            color: #232360;
            &::placeholder{
                color: rgba(34, 34, 96, 0.4);
            }
            }

            #custom-time-picker{
            margin-top: 15px;
            }
        </style>
        <div class="input-group">
          <input id="swal-input-strasse" class="swal2-input" placeholder="Strasse" value="${strasse}">
          <input id="swal-input-hausnummer" class="swal2-input" placeholder="Hausnummer" value="${hausnummer}">
          <input id="swal-input-plz" class="swal2-input" placeholder="PLZ" value="${plz}">
          <input id="swal-input-ort" class="swal2-input" placeholder="Ort" value="${ort}">
        </div>
        <div class="input-group">
          <select id="swal-input-status" class="swal2-select">
            <option value="Termin" ${status === 'Termin' ? 'selected' : ''}>Termin</option>
            <option value="Wiedervorlage" ${status === 'Wiedervorlage' ? 'selected' : ''}>Wiedervorlage</option>
            <option value="Keine Interesse" ${status === 'Keine Interesse' ? 'selected' : ''}>Keine Interesse</option>
            <option value="Nicht Da" ${status === 'Nicht Da' ? 'selected' : ''}>Nicht Da</option>
          </select> 
        </div>
        <div class="input-group">
          <input id="swal-input-vorname" class="swal2-input" placeholder="Vorname" value="${vorname}">
          <input id="swal-input-nachname" class="swal2-input" placeholder="Nachname" value="${nachname}">
          <input id="swal-input-email" class="swal2-input" placeholder="Email" value="${email}">
        </div>
        <div class="input-group">
          <input id="swal-input-phone_1" class="swal2-input" placeholder="Festnetz" value="${phone_1}">
          <input id="swal-input-phone_2" class="swal2-input" placeholder="Mobil" value="${phone_2}">
        </div>
        <div class="input-group">
          <input id="swal-input-jahresverbrauch" class="swal2-input" placeholder="Jahresverbrauch" value="${jahresverbrauch !== null ? jahresverbrauch : ''}">
          <input id="swal-input-personen_count" class="swal2-input" placeholder="Anzahl Personen" value="${personen_count !== null ? personen_count : ''}">
          <input id="swal-input-mtl_abschlag" class="swal2-input" placeholder="Monatlicher Abschlag" value="${mtl_abschlag !== null ? mtl_abschlag : ''}">
        </div>
        <div class="input-group">
          <select id="swal-input-art_flaeche" class="swal2-select">
            <option value="" disabled selected>Art der Fläche</option>
            <option value="" ${art_flaeche === '' ? 'selected' : ''}></option>
            <option value="Satteldach" ${art_flaeche === 'Satteldach' ? 'selected' : ''}>Satteldach</option>
            <option value="Flachdach" ${art_flaeche === 'Flachdach' ? 'selected' : ''}>Flachdach</option>
            <option value="Garagendach" ${art_flaeche === 'Garagendach' ? 'selected' : ''}>Garagendach</option>
            <option value="Walmdach" ${art_flaeche === 'Walmdach' ? 'selected' : ''}>Walmdach</option>
            <option value="Hallendach" ${art_flaeche === 'Hallendach' ? 'selected' : ''}>Hallendach</option>
            <option value="Blechdach" ${art_flaeche === 'Blechdach' ? 'selected' : ''}>Blechdach</option>
            <option value="Carport" ${art_flaeche === 'Carport' ? 'selected' : ''}>Carport</option>
          </select>
        </div>
        <div class="input-group">
          <select id="swal-input-dacheindeckung" class="swal2-select">
          <option value="" disabled selected>Dacheindeckung</option>
            <option value="" ${dacheindeckung === '' ? 'selected' : ''}></option>
            <option value="Ziegel" ${dacheindeckung === 'Ziegel' ? 'selected' : ''}>Ziegel</option>
            <option value="Blech" ${dacheindeckung === 'Blech' ? 'selected' : ''}>Blech</option>
            <option value="Kies" ${dacheindeckung === 'Kies' ? 'selected' : ''}>Kies</option>
            <option value="Bitumen" ${dacheindeckung === 'Bitumen' ? 'selected' : ''}>Bitumen</option>
            <option value="Faserzementplatten" ${dacheindeckung === 'Faserzementplatten' ? 'selected' : ''}>Faserzementplatten</option>
            <option value="Schiefer" ${dacheindeckung === 'Schiefer' ? 'selected' : ''}>Schiefer</option>
            <option value="Beton" ${dacheindeckung === 'Beton' ? 'selected' : ''}>Beton</option>
            <option value="Begrünt" ${dacheindeckung === 'Begrünt' ? 'selected' : ''}>Begrünt</option>
          </select>
        </div>
        <div class="input-group">
          <select id="swal-input-ausrichtung_flaeche" class="swal2-select">
            <option value="" disabled selected>Ausrichtung der Fläche</option>
            <option value="" ${ausrichtung_flaeche === '' ? 'selected' : ''}></option>
            <option value="Süd" ${ausrichtung_flaeche === 'Süd' ? 'selected' : ''}>Süd</option>
            <option value="Süd-West" ${ausrichtung_flaeche === 'Süd-West' ? 'selected' : ''}>Süd-West</option>
            <option value="Süd-Ost" ${ausrichtung_flaeche === 'Süd-Ost' ? 'selected' : ''}>Süd-Ost</option>
            <option value="Süd und Nord" ${ausrichtung_flaeche === 'Süd und Nord' ? 'selected' : ''}>Süd und Nord</option>
            <option value="Süd-Ost und Nord" ${ausrichtung_flaeche === 'Süd-Ost und Nord' ? 'selected' : ''}>Süd-Ost und Nord</option>
            <option value="Süd-West und Nord" ${ausrichtung_flaeche === 'Süd-West und Nord' ? 'selected' : ''}>Süd-West und Nord</option>
            <option value="Süd-West-Ost und Nord" ${ausrichtung_flaeche === 'Süd-West-Ost und Nord' ? 'selected' : ''}>Süd-West-Ost und Nord</option>
            <option value="Ost" ${ausrichtung_flaeche === 'Ost' ? 'selected' : ''}>Ost</option>
            <option value="Ost und West" ${ausrichtung_flaeche === 'Ost und West' ? 'selected' : ''}>Ost und West</option>
            <option value="Ost und Nord" ${ausrichtung_flaeche === 'Ost und Nord' ? 'selected' : ''}>Ost und Nord</option>
            <option value="West" ${ausrichtung_flaeche === 'West' ? 'selected' : ''}>West</option>
            <option value="West und Nord" ${ausrichtung_flaeche === 'West und Nord' ? 'selected' : ''}>West und Nord</option>
            <option value="Nord" ${ausrichtung_flaeche === 'Nord' ? 'selected' : ''}>Nord</option>
            <option value="Nord-West" ${ausrichtung_flaeche === 'Nord-West' ? 'selected' : ''}>Nord-West</option>
            <option value="Nord-Ost" ${ausrichtung_flaeche === 'Nord-Ost' ? 'selected' : ''}>Nord-Ost</option>   
          </select>
        </div>
        <div class="input-group">
          <input id="swal-input-datum" type="date" class="swal2-input" value="${formattedDatum}">
          <div id="custom-time-picker"></div>
        </div>
        <div class="input-group">
          <input id="swal-input-kommentar" class="swal2-input" placeholder="Kommentar" value="${kommentar}">
        </div>
      `,
      didOpen: () => {
        ReactDOM.render(
          <CustomTimePicker 
            value={localSelectedTime}
            onChange={(time) => localSelectedTime = time} // Update local variable
            onHourValidChange={(valid) => setHourValid(valid)}
            onMinuteValidChange={(valid) => setMinuteValid(valid)}
          />,
          document.getElementById('custom-time-picker')
        );
      },
      focusConfirm: false,
      preConfirm: () => {
      },
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      allowEnterKey: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'OK',
      preConfirm: () => {
        const newStrasse = document.getElementById('swal-input-strasse').value;
        const newOrt = document.getElementById('swal-input-ort').value;
        const newHausnummer = document.getElementById('swal-input-hausnummer').value;
        const newPLZ = document.getElementById('swal-input-plz').value;
        const newVorname = document.getElementById('swal-input-vorname').value;
        const newNachname = document.getElementById('swal-input-nachname').value;
        const newEmail = document.getElementById('swal-input-email').value;
        const newPhone_1 = document.getElementById('swal-input-phone_1').value;
        const newPhone_2 = document.getElementById('swal-input-phone_2').value;
        const newJahresverbrauch = document.getElementById('swal-input-jahresverbrauch').value;
        const newPersonenCount = document.getElementById('swal-input-personen_count').value;
        const newMtlAbschlag = document.getElementById('swal-input-mtl_abschlag').value;
        const newDatum = document.getElementById('swal-input-datum').value;
        const newUhrzeit = localSelectedTime;


        // Überprüfen, ob die Straße gültig ist
        if (!validateText(newStrasse)) {
          MySwal.showValidationMessage('Die Straße darf nicht leer sein und darf nur Buchstaben enthalten!');
          return false;
        }
        // Überprüfen, ob der Ort gültig ist
        if (!validateText(newOrt)) {
          MySwal.showValidationMessage('Der Ort darf nicht leer sein und darf nur Buchstaben enthalten!');
          return false;
        }
        // Überprüfen, ob der Name gültig ist
        if (!validateName(newVorname) || !validateName(newNachname)) {
          MySwal.showValidationMessage('Name darf nur Buchstaben enthalten!');
          return false;
        }
        // Überprüfen, ob Hausnummer gültig ist
        if (!validateHausnummer(newHausnummer)) {
          MySwal.showValidationMessage('Hausnummer darf nicht leer sein und darf nur aus Zahlen und Buchstaben bestehen!');
          return false;
        }

        // Überprüfen, ob Plz gültig ist
        if (!validatePLZ(newPLZ)) {
          MySwal.showValidationMessage('Postleitzahl darf nicht leer sein und darf nur aus Zahlen bestehen!');
          return false;
        }

        // Überprüfen, ob das Datum gültig ist
        if (!validateDate(newDatum)) {
            MySwal.showValidationMessage('Datum darf nicht leer sein!');
            return false;
        }

        // Überprüfen, ob die E-Mail gültig ist
        if (!validateEmail(newEmail)) {
          MySwal.showValidationMessage('Bitte geben Sie eine gültige E-Mail-Adresse ein!');
          return false;
        }
  
        // Überprüfen, ob die Telefonnummern gültig sind
        if (!validatePhoneNumber(newPhone_1) || !validatePhoneNumber(newPhone_2)) {
          // Wenn eine der Telefonnummern ungültig ist, zeige eine Fehlermeldung und verhindere, dass die Aktion ausgeführt wird
          MySwal.showValidationMessage('Telefonnummern dürfen nur aus Zahlen bestehen!');
          return false;
        }

        // Überprüfen, ob Jahresverbrauch gültig ist
        if (!validateJahresverbrauch(newJahresverbrauch)) {
          MySwal.showValidationMessage('Jahresverbrauch darf nur aus Zahlen bestehen!');
          return false;
        }

        // Überprüfen, ob personen_count gültig ist
        if (!validatePersonen_im_Haushalt(newPersonenCount)) {
          MySwal.showValidationMessage('Anzahl Personen darf nur aus Zahlen bestehen!');
          return false;
        }

        // Überprüfen, ob mtl_abschlag gültig ist
        if (!validateMtl_Abschlag(newMtlAbschlag)) {
          MySwal.showValidationMessage('Monatlicher Abschlag darf nur aus Zahlen bestehen!');
          return false;
        }

        // Überprüfen, ob die Uhrzeit gültig ist
        if (!validateUhrzeit(newUhrzeit)) {
          MySwal.showValidationMessage('Bitte geben Sie eine gültige Uhrzeit ein!');
          return false;
        }


  
        // Wenn alles gültig ist, sende die Daten
        return [
          document.getElementById('swal-input-strasse').value,
          document.getElementById('swal-input-hausnummer').value,
          document.getElementById('swal-input-plz').value,
          document.getElementById('swal-input-ort').value,
          document.getElementById('swal-input-status').value,
          document.getElementById('swal-input-vorname').value,
          document.getElementById('swal-input-nachname').value,
          document.getElementById('swal-input-email').value,
          document.getElementById('swal-input-phone_1').value,
          document.getElementById('swal-input-phone_2').value,
          document.getElementById('swal-input-jahresverbrauch').value,
          document.getElementById('swal-input-personen_count').value,
          document.getElementById('swal-input-mtl_abschlag').value,
          document.getElementById('swal-input-art_flaeche').value,
          document.getElementById('swal-input-dacheindeckung').value,
          document.getElementById('swal-input-ausrichtung_flaeche').value,
          document.getElementById('swal-input-datum').value,
          localSelectedTime, // Use local variable
          document.getElementById('swal-input-kommentar').value,
        ];
      }
    });
  
    if (formValues) {
      const [newStrasse, newHausnummer, newPLZ, newOrt, newStatus, newVorname, newNachname, newEmail, newPhone_1, newPhone_2, newJahresverbrauch, newPersonenCount, newMtlAbschlag, newArtFlaeche, newDacheindeckung, newAusrichtungFlaeche, newDatum, newTime, newKommentar] = formValues;
      


      const fields = {
        strasse: newStrasse,
        hausnummer: newHausnummer,
        plz: newPLZ,
        ort: newOrt,
        status: newStatus,
        vorname: newVorname,
        nachname: newNachname,
        email: newEmail,
        phone_1: newPhone_1,
        phone_2: newPhone_2,
        jahresverbrauch: newJahresverbrauch,
        personen_count: newPersonenCount,
        mtl_abschlag: newMtlAbschlag,
        art_flaeche: newArtFlaeche,
        dacheindeckung: newDacheindeckung,
        ausrichtung_flaeche: newAusrichtungFlaeche,
        datum: newDatum,
        uhrzeit: newTime,
        kommentar: newKommentar,
      };
  
      setSelectedTime(newTime); // Update main state

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Eintrag gespeichert!",
        background: "#fcf6f9",
        color: "#222260",
        showConfirmButton: false,
        timer: 1500
      });
      // Handle the form values, for example, by making an API call to update the data
      updateTermin(id, fields)
        .then(response => {
          // Handle the response here
        })
        .catch(error => {
          // Handle the error here
        });
    }
  };