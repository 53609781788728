import React, { useEffect } from 'react'
import styled from 'styled-components'
import { InnerLayout } from '../../styles/Layouts';

function Home() {


    return (
        <HomeStyled> 
            <InnerLayout>      
            <div className='begruessung'>
                Willkommen zurück!
            </div>
            </InnerLayout> 
        </HomeStyled>
        )
    }

const HomeStyled = styled.div`
.begruessung {
    display:flex;
    justify-content:center;
    font-size:50px;
}
`;
export default Home