import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../../context/globalContext';
import styled from 'styled-components';
import { InnerLayout } from '../../styles/Layouts';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function Statistik() {
    const { createStatistik, StatistikData } = useGlobalContext();
    const [sortedData, setSortedData] = useState([]);

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [startDate, setStartDate] = useState(getTodayDate());
    const [endDate, setEndDate] = useState(getTodayDate());

    useEffect(() => {
      const newEndDate = new Date(endDate);
      newEndDate.setDate(newEndDate.getDate());
      newEndDate.setHours(0, 0, 0, 0); // Setzt auf Mitternacht
  
      const newstartDate = new Date(startDate);
      newstartDate.setHours(0, 0, 0, 0); // Setzt auf Mitternacht
  
      createStatistik(newstartDate, newEndDate); // Start- und Enddatum als Parameter übergeben
  }, [startDate, endDate]);
    
    useEffect(() => {
        // Sortieren der Ranglisten-Daten nach Anzahl der Termine
        const sorted = [...StatistikData].sort((a, b) => b.anzahlTermine - a.anzahlTermine);
        setSortedData(sorted);
    }, [StatistikData]);

    const filterDataByDateRange = (data) => {
      // Filter out entries with "Nicht zugewiesen"
      return data.filter(item => item._id !== "Nicht zugewiesen");
  };

  const filteredData = filterDataByDateRange(sortedData);

    return (
        <InnerLayout>
            <CenteredHeader>Statistik</CenteredHeader>
            <DatepickerStyled>
            <Form>
                <InputGroup className='input-control'>
                    <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder='Startdatum eingeben'
                    />
                </InputGroup>
            </Form>
            <Form>
                <InputGroup className='input-control'>
                    <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder='Enddatum eingeben'
                    />
                </InputGroup>
            </Form>
            </DatepickerStyled>
            <Table className="rangliste-table">
                <thead>
                    <TableRow>
                        <TableHeader>Verkäufer</TableHeader>
                        <TableHeader>Schreibquote</TableHeader>
                    </TableRow>
                </thead>
                <tbody>
                    {filteredData.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item._id}</TableCell>
                            <TableCell>{item.quotient}</TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </InnerLayout>
    );
}

export default Statistik;


const CenteredHeader = styled.h1` 
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Table = styled.table`
  margin-top: 1.5rem;
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  overflow:hidden;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #FCF6F9;
  }
  &:not(:last-child) {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  padding: 12px;
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  background-color: #FCF6F9;
`;

const DatepickerStyled = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
gap: 1rem;
input, textarea, select{
  font-family: inherit;
  font-size: inherit;
  outline: none;
  border: none;
  padding: .5rem 1rem;
  border-radius: 5px;
  border: 2px solid #fff;
  background: transparent;
  resize: none;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
  color: rgba(34, 34, 96, 0.9);
  &::placeholder{
      color: rgba(34, 34, 96, 0.4);
  }
}
.input-control{
  input{
      width: 100%;
  }
}
`;
