import React, { useState } from 'react'
import styled from 'styled-components'
import avatar from '../../img/avatar.png'
import { logout } from '../../utils/Icons'
import { menuItems } from '../../utils/menuItems'
import { useGlobalContext } from '../../context/globalContext'

function Navigation({active, setActive, closeNav}) {
    const name = JSON.parse(localStorage.getItem('benutzer'));
    const {handleLogout} = useGlobalContext();

    const handleSubmit = () => {
        handleLogout();
    };

    const handleItemClick = (itemId) => {
        setActive(itemId);
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        if (mediaQuery.matches) {
            closeNav(); // Schließe die Navigation nur auf mobilen Geräten
        }
    };
    
    return (
        <NavStyled>
            <div className="user-con">
                <img src={avatar} alt="" />
                <div className="text">
                    <h2>{name}</h2>
                    <p>Verkäufer</p>
                </div>
            </div>
            <ul className="menu-items">
                {menuItems.filter(item => [3,6].includes(item.id)).map((item) => {
                    return (
                        <li
                            key={item.id}
                            onClick={() => handleItemClick(item.id)}
                            className={active === item.id ? 'active': ''}
                        >
                            {item.icon}
                            <span>{item.title}</span>
                        </li>
                    );
                })}
            </ul>
            <div className="bottom-nav">
            <li onClick={handleSubmit}> {/* Klickereignis für Ausloggen hinzufügen */}
                    {logout}
                    Ausloggen
                </li>
            </div>
        </NavStyled>
    );
    
}

const NavStyled = styled.nav`
    padding: 2rem 1.5rem;
    width: 374px;
    height: 100%;
    background: rgba(252, 246, 249);
    border: 3px solid #FFFFFF;
    backdrop-filter: blur(4.5px);
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1366px) 
    and (orientation: landscape) {
        width: 325px;
    }
    .user-con{
        height: 100px;
        display: flex;
        align-items: center;
        gap: 1rem;
        img{
            width: 80px;
            height: 80px;
            border-radius: 50%;
            object-fit: cover;
            background: #fcf6f9;
            border: 2px solid #FFFFFF;
            padding: .2rem;
            box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.06);
        }
        h2{
            color: rgba(34, 34, 96, 1);
        }
        p{
            color: rgba(34, 34, 96, .6);
        }
    }

    .menu-items{
        flex: 1;
        display: flex;
        flex-direction: column;
        li{
            display: grid;
            grid-template-columns: 40px auto;
            align-items: center;
            margin: .6rem 0;
            font-weight: 500;
            cursor: pointer;
            transition: all .4s ease-in-out;
            color: rgba(34, 34, 96, .6);
            padding-left: 1rem;
            position: relative;

            &:hover {
                color: rgba(34, 34, 96, 1) !important;
                transform: scale(1.05);
                transition: all .1s ease-in-out;
                i {
                    color: rgba(34, 34, 96, 1) !important;
                    transform: scale(1.05);
                }
            }
            i{
                color: rgba(34, 34, 96, 0.6);
                font-size: 1.4rem;
                transition: all .1s ease-in-out;
            }
        }
    }

    .bottom-nav {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;

    li {
        display: grid;
        grid-template-columns: 30px auto;
        align-items: center;
        margin: .6rem 0;
        font-weight: 500;
        cursor: pointer;
        transition: all .4s ease-in-out;
        color: rgba(34, 34, 96, .6);
        padding-left: 1rem;
        position: relative;

        &:hover {
            color: darkred; /* Ändere die Textfarbe auf dunkelrot */
            transform: scale(1.05);
            transition: all .1s ease-in-out;
            i {
                color: darkred; /* Ändere die Icon-Farbe auf dunkelrot */
                transform: scale(1.05);
            }
        }

        i {
            color: rgba(34, 34, 96, 0.6);
            font-size: 1.4rem;
            transition: all .1s ease-in-out;
        }
    }
}
    

    .active{
        color: rgba(34, 34, 96, 1) !important;
        i{
            color: rgba(34, 34, 96, 1) !important;
        }
        &::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background: #222260;
            border-radius: 0 10px 10px 0;
        }
    }
`;

export default Navigation