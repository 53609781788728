import React, { useContext, useState } from "react"
import axios from 'axios'

const BASE_URL = "https://api.swb-aussendienst.de:5001/api/v1/";
const API_KEY = "2c1c99b8d64e4f9ca38f3aef16a93728";

const GlobalContext = React.createContext()

export const GlobalProvider = ({ children }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [incomes, setIncomes] = useState([])
    const [expenses, setExpenses] = useState([])
    const [error, setError] = useState(null)
    const [werberList, setWerberList] = useState([]);
    const [verkaeuferList, setVerkaeuferList] = useState([]);
    const [terminstatenList, setTerminstatenList] = useState([]);
    const [strassenList, setStrassenList] = useState([]);
    const [termineDesWerbers, setTermineDesWerbers] = useState([]);
    const [AllTermine, setAllTermine] = useState([]);
    const [AllWiedervorlagen, setAllWiedervorlagen] = useState([]);
    const [AllStorniert, setAllStorniert] = useState([]);
    const [TrackingData, setTrackingData] = useState([]);
    const [BruttoTermineData, setBruttoTermineData] = useState([]);
    const [NettoTermineData, setNettoTermineData] = useState([]);
    const [StatistikData, setStatistikData] = useState([]);
    const [LatestEntryStrasse, setLatestEntryStrasse] = useState([]);
    const [AllMitarbeiter, setAllMitarbeiter] = useState([]);


    // Funktion zum Hinzufügen des API-Schlüssels zu den Anfrage-Headern
    const addApiKeyToHeaders = (headers) => {
        headers['X-API-Key'] = API_KEY;
        return headers;
    }

    // Konfiguration von Axios-Interceptors zum Hinzufügen des API-Schlüssels zu allen Anfragen
    axios.interceptors.request.use(function (config) {
        config.headers = addApiKeyToHeaders(config.headers);
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    //calculate incomes
    const addIncome = async (income) => {
        const response = await axios.post(`${BASE_URL}add-income`, income)
            .catch((err) => {
                setError(err.response.data.message)
            })
        getIncomes()
    }


    //calculate incomes
    const addTermin = async (termin) => {
        const response = await axios.post(`${BASE_URL}add-termin`, termin)
            .catch((err) => {
                setError(err.response.data.message)
            })
    }

    const addMitarbeiter = async (mitarbeiter) => {
        const response = await axios.post(`${BASE_URL}add-mitarbeiter`, mitarbeiter)
            .catch((err) => {
                setError(err.response.data.message)
            })
    }

    const getIncomes = async () => {
        const response = await axios.get(`${BASE_URL}get-incomes`)
        setIncomes(response.data)
        console.log(response.data)
    }

    const deleteIncome = async (id) => {
        const res = await axios.delete(`${BASE_URL}delete-income/${id}`)
        getIncomes()
    }

    const totalIncome = () => {
        let totalIncome = 0;
        incomes.forEach((income) => {
            totalIncome = totalIncome + income.amount
        })

        return totalIncome;
    }

    const updateMitarbeiter = async (id, updatedData) => {
        try {
            const response = await axios.put(`${BASE_URL}update-mitarbeiter/${id}`, updatedData);
            console.log(response.data);
        } catch (error) {
            console.error('Fehler beim Aktualisieren des Mitarbeiters:', error);
        }

        getAllMitarbeiter()
    };

    const updateTermin = async (id, updatedData) => {
        try {
            const response = await axios.put(`${BASE_URL}update-termin/${id}`, updatedData);
            console.log(response.data);
        } catch (error) {
            console.error('Fehler beim Aktualisieren des Termins:', error);
        }
    };

    const deleteMitarbeiter = async (id) => {
        const res  = await axios.delete(`${BASE_URL}delete-mitarbeiter/${id}`)
        getAllMitarbeiter()
    }

    const deleteTermin = async (id) => {
        const res = await axios.delete(`${BASE_URL}delete-termin/${id}`)
    }

    const storniereTermin = async (id) => {
        const res = await axios.put(`${BASE_URL}storniere-termin/${id}`)

    }

    
    const getAllTermine = async (startDate, endDate) => {
        try {
            const response = await axios.get(`${BASE_URL}get-all-termine?startDate=${startDate}&endDate=${endDate}`)
            setAllTermine(response.data) // Hier wird die werberList aktualisiert
            console.log(response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der Termine:", error)
        }
    }

    const getAllWiedervorlagen = async () => {
        try {
            const response = await axios.get(`${BASE_URL}get-all-wiedervorlagen`)
            setAllWiedervorlagen(response.data) // Hier wird die werberList aktualisiert
            console.log(response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der Wiedervorlagen:", error)
        }
    }

    const getAllStorniert = async () => {
        try {
            const response = await axios.get(`${BASE_URL}get-all-storniert`)
            setAllStorniert(response.data) // Hier wird die werberList aktualisiert
            console.log(response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der Stornierten Termine:", error)
        }
    }

    const getAllMitarbeiter = async () => {
        try {
            const response = await axios.get(`${BASE_URL}get-all-mitarbeiter`)
            setAllMitarbeiter(response.data)
            console.log(response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der Mitarbeiter:", error)
        }
    }


    const getAllActiveWerber = async () => {
        try {
            const response = await axios.get(`${BASE_URL}get-all-werber`)
            setWerberList(response.data) // Hier wird die werberList aktualisiert
            console.log(response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der Werberliste:", error)
        }
    }

    const getAllActiveVerkaeufer = async () => {
        try {
            const response = await axios.get(`${BASE_URL}get-all-verkaeufer`)
            setVerkaeuferList(response.data) // Hier wird die verkaeuferList aktualisiert
            console.log(response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der Verkäuferliste:", error)
        }
    }

    const getAllTerminstaten = async () => {
        try {
            const response = await axios.get(`${BASE_URL}get-all-terminstaten`)
            setTerminstatenList(response.data) // Hier wird die werberList aktualisiert
            console.log(response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der Terminstatenliste:", error)
        }
    }

    const getAllStrassen = async () => {
        try {
            const response = await axios.get(`${BASE_URL}get-all-strassen`)
            setStrassenList(response.data) // Hier wird die strassenlist aktualisiert
            console.log(response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der Strassenliste:", error)
        }
    }
    

    const getBruttoTermine = async (startDate, endDate) => {
        try {
            const response = await axios.get(`${BASE_URL}get-brutto-termine?startDate=${startDate}&endDate=${endDate}`)
            setBruttoTermineData(response.data) // Hier wird die werberList aktualisiert
            console.log("BruttoTermineData:", response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der BruttoTermine:", error)
        }
    }

    const getNettoTermine = async (startDate, endDate) => {
        try {
            const response = await axios.get(`${BASE_URL}get-netto-termine?startDate=${startDate}&endDate=${endDate}`)
            setNettoTermineData(response.data) // Hier wird die werberList aktualisiert
            console.log("NettoTermineData:", response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der NettoTermine:", error)
        }
    }
    const updateSelectedTermine = async (selectedIds, selectedVerkaeufer) => {
        try {
            // Wandeln Sie selectedIds in ein Array um
            const selectedIdsArray = Array.from(selectedIds);
    
            const response = await axios.put(`${BASE_URL}update-selected-termine`, {
                selectedIds: selectedIdsArray,
                selectedVerkaeufer: selectedVerkaeufer
            });
            console.log(response.data);
            return response.data; // Wenn eine Antwort vom Server erforderlich ist
        } catch (error) {
            console.error('Fehler beim Aktualisieren der ausgewählten Termine:', error);
            throw error; // Behandlung des Fehlers oder Weitergabe an den Aufrufer
        }
    };

    const createStatistik = async (startDate, endDate) => {
        try {
            const response = await axios.get(`${BASE_URL}create-statistik?startDate=${startDate}&endDate=${endDate}`)
            setStatistikData(response.data) // Hier wird die werberList aktualisiert
            console.log("StatistikData:", response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der Statistik:", error)
        }
    }


    const getLatestEntryStrasse = async (selectedWerber) => {
        try {
            const response = await axios.get(`${BASE_URL}get-latest-entry-strasse/${selectedWerber}`)
            setLatestEntryStrasse(response.data) // Hier wird die werberList aktualisiert
            console.log("Letzter Strassenname:", response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der letzten Straßenname:", error)
        }
    }

    const trackWerber = async () => {
        try {
            const response = await axios.get(`${BASE_URL}track-werber`)
            setTrackingData(response.data) // Hier wird die werberList aktualisiert
            console.log("TrackingData:", response.data)
        } catch (error) {
            console.error("Fehler beim Abrufen der Werberliste:", error)
        }
    }

    const getTermineByWerber = async (selectedWerber) => {
        try {
            const response = await axios.get(`${BASE_URL}get-termine-by-werber/${selectedWerber}`);
            setTermineDesWerbers(response.data); // Setze die Termine des ausgewählten Werbers
            console.log("DAS HIER:", selectedWerber);
            console.log("DAS HIER:", response.data);
            console.log("DAS HIER:", termineDesWerbers);
            console.log(response.data);
        } catch (error) {
            console.error('Fehler beim Abrufen der Termine:', error);
        }
    }
    const handleLogin = async (email, password) => {
        try {
            const response = await axios.post(`${BASE_URL}login`, {
                email,
                passwort: password,
            });
            const { token, redirectUrl, userRole, benutzer } = response.data;
            localStorage.setItem('token', token);
            localStorage.setItem('userRole', JSON.stringify(userRole));
            localStorage.setItem('benutzer', JSON.stringify(benutzer));
            console.log(response.data)

            // Weiterleitung zur entsprechenden URL basierend auf der redirectUrl
            if (redirectUrl) {
                window.location.href = redirectUrl;
            } else {
                // Standard-Weiterleitung, falls keine spezifische URL zurückgegeben wird
                console.log("Keine Weiterleitungs-URL erhalten. Weiterleitung zur Standardseite.");
                window.location.href = '/';
            }
        } catch (error) {
            // Fehlerbehandlung
            if (error.response && error.response.status === 401) {
                // Fehler: Ungültige Anmeldeinformationen
                throw new Error('Falsche Anmeldeinformationen. Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort.');
            } else {
                // Generische Fehlermeldung
                throw new Error('Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.');
            }
        }
    };

const handleLogout = async () => {
    try {
        // Authorization-Token aus dem Local Storage lesen
        const token = localStorage.getItem('token');

        // Wenn kein Token im Local Storage vorhanden ist, handle den Fehler entsprechend
        if (!token) {
            console.error('Authorization-Token nicht im Local Storage gefunden');
            return;
        }

        // Axios-Konfiguration für die Abmeldeanfrage
        const config = {
            headers: {
                'Authorization': `Bearer ${token}` // Setze den Token im Authorization-Header
            }
        };

        // Abmelden-Anfrage an den Server senden
        const response = await axios.post(`${BASE_URL}logout`, {}, config);
        localStorage.removeItem('token');
        localStorage.removeItem('userRole');
        localStorage.removeItem('benutzer');

        // Wenn die Abmelden-Anfrage erfolgreich ist, handle den Erfolgsfall entsprechend
        console.log('Erfolgreich abgemeldet');
        window.location.href = '/';
    } catch (error) {
        // Wenn ein Fehler auftritt, handle den Fehlerfall entsprechend
        console.error('Fehler beim Abmelden:', error.message);
    }
};






    return (
        <GlobalContext.Provider value={{
            addIncome,
            handleLogin,
            handleLogout,
            getIncomes,
            incomes,
            deleteIncome,
            deleteTermin,
            storniereTermin,
            expenses,
            addMitarbeiter,
            addTermin,
            getAllActiveWerber,
            werberList,
            setWerberList,
            getAllActiveVerkaeufer,
            verkaeuferList,
            setVerkaeuferList,
            getAllTerminstaten,
            terminstatenList,
            setTerminstatenList,
            getAllStrassen,
            strassenList,
            setStrassenList,
            getTermineByWerber,
            termineDesWerbers,
            setTermineDesWerbers,
            getAllMitarbeiter,
            AllMitarbeiter,
            getAllTermine,
            AllTermine,
            setAllTermine,
            getAllWiedervorlagen,
            AllWiedervorlagen,
            setAllWiedervorlagen,
            getAllStorniert,
            setAllStorniert,
            AllStorniert,
            trackWerber,
            TrackingData,
            setTrackingData,
            getBruttoTermine,
            BruttoTermineData,
            setBruttoTermineData,
            getNettoTermine,
            NettoTermineData,
            setNettoTermineData,
            getLatestEntryStrasse,
            updateTermin,
            updateSelectedTermine,
            updateMitarbeiter,
            deleteMitarbeiter,
            createStatistik,
            StatistikData,
            setStatistikData,
            LatestEntryStrasse,
            setLatestEntryStrasse,
            totalIncome,
            error,
            setError
        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export const useGlobalContext = () => {
    return useContext(GlobalContext)
}
