import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../context/globalContext';
import Button from '../../Components/Button/Button';
import { plus } from '../../utils/Icons';
import Swal from 'sweetalert2'

function MitarbeiterForm(props) {
    const { addMitarbeiter, error, setError } = useGlobalContext();
    const [inputState, setInputState] = useState({
        vorname: '',
        nachname: '',
        rolle: '',
        phone_1: '',
        phone_2: '',
        email: '',
        passwort: '',
    });

    const { vorname, nachname, rolle, phone_1, phone_2, email, passwort } = inputState;

    const handleInput = name => e => {
        setInputState({...inputState, [name]: e.target.value});
        setError('');
    };


    const handleSubmit = e => {
    e.preventDefault();
    addMitarbeiter(inputState);
    setInputState({
        ...inputState,
        vorname: '',
        nachname: '',
        rolle: '',
        phone_1: '',
        phone_2: '',
        email: '',
        passwort: '',
    });
    if (props.onSubmit) {
        props.onSubmit();
    }
    Swal.fire({
        position: "center",
        icon: "success",
        title: "Mitarbeiter hinzugefügt!",
        background: "#fcf6f9",
        color: "#222260",
        showConfirmButton: false,
        timer: 1500
      });
};

    // Funktion zum Überprüfen der Bedingungen für das Erscheinen des Buttons
    const shouldShowButton = () => {
        return vornameValid && nachnameValid && emailValid && phone_1Valid && phone_2Valid  && rolle.trim() !== '' && passwort.trim() !== '';
    };



    // Validierung für Vorname
    const [vornameValid, setVornameValid] = useState(false);
    const [vornameError, setVornameError] = useState('');
    const [vornameTouched, setVornameTouched] = useState(false);

    const handleVornameChange = (e) => {
        const newVorname = e.target.value;
        setInputState({...inputState, vorname: newVorname});
        validateVorname(newVorname);
    };

    const validateVorname = (newVorname) => {
            if (newVorname === '') {
                setVornameValid(false);
                setVornameError('Vorname darf nicht leer sein.');
            } else if (/\d/.test(newVorname)) {
                setVornameValid(false);
                setVornameError('Vorname darf keine Zahlen enthalten.');
            } else {
                setVornameValid(true);
                setVornameError('');
            }
    };

    const handleVornameBlur = () => {
        setVornameTouched(true);
        validateVorname(vorname);
    };

    // Validierung für Nachname
    const [nachnameValid, setNachnameValid] = useState(false);
    const [nachnameError, setNachnameError] = useState('');
    const [nachnameTouched, setNachnameTouched] = useState(false);

    const handleNachnameChange = (e) => {
        const newNachname = e.target.value;
        setInputState({...inputState, nachname: newNachname});
        validateNachname(newNachname);
    };

    const validateNachname = (newNachname) => {
            if (newNachname === '') {
                setNachnameValid(false);
                setNachnameError('Nachname darf nicht leer sein.');
            } else if (/\d/.test(newNachname)) {
                setNachnameValid(false);
                setNachnameError('Nachname darf keine Zahlen enthalten.');
            } else {
                setNachnameValid(true);
                setNachnameError('');
        }
    };

    const handleNachnameBlur = () => {
        setNachnameTouched(true)
        validateNachname(nachname);
    };

    // Validierung für Email
    const [emailValid, setEmailValid] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [emailTouched, setEmailTouched] = useState(false);

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setInputState({...inputState, email: newEmail});
        validateEmail(newEmail);
    };




    const validateEmail = (newEmail) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (newEmail === '') {
                setEmailValid(false);
                setEmailError('E-Mail-Adresse darf nicht leer sein.');
            } else if (!emailRegex.test(newEmail)) {
                setEmailValid(false);
                setEmailError('Ungültige E-Mail-Adresse.');
            } else {
                setEmailValid(true);
                setEmailError('');
            }
    };

    const handleEmailBlur = () => {
        setEmailTouched(true)
        validateEmail(email);
    };

    // Validierung für phone_1
    const [phone_1Valid, setPhone_1Valid] = useState(false);
    const [phone_1Error, setPhone_1Error] = useState('');
    const [phone_1Touched, setPhone_1Touched] = useState(false);

    const handlePhone_1Change = (e) => {
        const newPhone_1 = e.target.value;
        setInputState({...inputState, phone_1: newPhone_1});
        validatePhone_1(newPhone_1);
    };


    const validatePhone_1 = (newPhone_1) => {

            if (newPhone_1 === '') {
                setPhone_1Valid(false);
                setPhone_1Error('Telefonnummer darf nicht leer sein.');
            } else if (!/^[^a-zA-Z]+$/.test(newPhone_1)) {
                setPhone_1Valid(false);
                setPhone_1Error('Ungültige Telefonnummer');
            } else {
                setPhone_1Valid(true);
                setPhone_1Error('');
            }
    };

    const handlePhone_1Blur = () => {
        setPhone_1Touched(true)
        validatePhone_1(phone_1);
    };

    // Validierung für phone_2
    const [phone_2Valid, setPhone_2Valid] = useState(true);
    const [phone_2Error, setPhone_2Error] = useState('');
    const [phone_2Touched, setPhone_2Touched] = useState(false);

    const handlePhone_2Change = (e) => {
        const newPhone_2 = e.target.value;
        setInputState({...inputState, phone_2: newPhone_2});
        validatePhone_2(newPhone_2);
    };

    const validatePhone_2 = (newPhone_2) => {

        if (newPhone_2 === '' || /^[^a-zA-Z]+$/.test(newPhone_2)) {
            setPhone_2Valid(true);
            setPhone_2Error('');
        } else {
            setPhone_2Valid(false);
            setPhone_2Error('Ungültige Telefonnummer');
        }
    };

    const handlePhone_2Blur = () => {
        setPhone_2Touched(true)
        validatePhone_2(phone_2);
    };

    // Validierung für Passwort
    const [passwortValid, setPasswortValid] = useState(false);
    const [passwortError, setPasswortError] = useState('');
    const [passwortTouched, setPasswortTouched] = useState(false);

    const handlePasswortChange = (e) => {
        const newPasswort = e.target.value;
        setInputState({...inputState, passwort: newPasswort});
        validatePasswort(newPasswort);
    };


    const validatePasswort = (newPasswort) => {

            if (newPasswort === '') {
                setPasswortValid(false);
                setPasswortError('Passwort darf nicht leer sein.');
            } else {
                setPasswortValid(true);
                setPasswortError('');
        }
    };

    const handlePasswortBlur = () => {
        setPasswortTouched(true);
        validatePasswort(passwort);
    };

    return (
        <FormStyled onSubmit={handleSubmit}>
            {error && <p className='error'>{error}</p>}
            <div className="input-control">
                <input 
                    type="text" 
                    value={vorname}
                    name={'vorname'} 
                    placeholder="* Vorname"
                    onChange={handleVornameChange}
                    onBlur={handleVornameBlur}
                    style={{ borderColor: (vornameValid || !vornameTouched) ? '' : 'red' }}
                />
                {vornameError && <span style={{ color: 'red' }}>{vornameError}</span>}
            </div>
            <div className="input-control">
                <input 
                    type="text" 
                    value={nachname}
                    name={'nachname'} 
                    placeholder="* Nachname"
                    onChange={handleNachnameChange}
                    onBlur={handleNachnameBlur}
                    style={{ borderColor: (nachnameValid || !nachnameTouched) ? '' : 'red' }}
                />
                {nachnameError && <span style={{ color: 'red' }}>{nachnameError}</span>}
                        </div>
            <div className="selects input-control">
                <select required value={rolle} name="rolle" id="rolle" style={{ width: '100%' }} onChange={handleInput('rolle')}>
                    <option value="" disabled>* Rolle auswählen</option>
                    <option value="Admin">Admin</option>
                    <option value="Verkäufer">Verkäufer</option>
                    <option value="Werber">Werber</option>
                    <option value="Bestätiger">Bestätiger</option>
                </select>
            </div>
            <div className="input-control">
                <input 
                    type="text" 
                    value={phone_1}
                    name={'phone_1'} 
                    placeholder="* Telefon 1"
                    onChange={handlePhone_1Change}
                    onBlur={handlePhone_1Blur}
                    style={{ borderColor: (phone_1Valid || !phone_1Touched) ? '' : 'red' }}
                />
                {phone_1Error && <span style={{ color: 'red' }}>{phone_1Error}</span>}
            </div>
            <div className="input-control">
                <input 
                    type="text" 
                    value={phone_2}
                    name={'phone_2'} 
                    placeholder="Telefon 2"
                    onChange={handlePhone_2Change}
                    onBlur={handlePhone_2Blur}
                    style={{ borderColor: (phone_2Valid || !phone_2Touched) ? '' : 'red' }}
                />
                {phone_2Error && <span style={{ color: 'red' }}>{phone_2Error}</span>}
            </div>
            <div className="input-control">
                <input 
                    type="email" 
                    value={email}
                    name={'email'} 
                    placeholder="* E-Mail-Adresse"
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    style={{ borderColor: (emailValid || !emailTouched) ? '' : 'red' }}
                />
                {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                </div>
            <div className="input-control">
                <input 
                    type="text" 
                    value={passwort}
                    name={'passwort'}
                    placeholder="* Passwort"
                    onChange={handlePasswortChange}
                    onBlur={handlePasswortBlur}
                    style={{ borderColor: (passwortValid || !passwortTouched) ? '' : 'red' }}
                />
                {passwortError && <span style={{ color: 'red' }}>{passwortError}</span>}
            </div>
            {shouldShowButton() && (
            <div className="submit-btn">
                <Button 
                    name={'Benutzer anlegen'}
                    icon = {plus}
                    bPad={'.8rem 1.6rem'}
                    bRad={'30px'}
                    bg={'var(--color-accent'}
                    color={'#fff'}
                />
            </div>
        )}
        </FormStyled>
    )
}


const FormStyled = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    input, textarea, select{
        font-family: inherit;
        font-size: inherit;
        outline: none;
        border: none;
        padding: .5rem 1rem;
        border-radius: 5px;
        border: 2px solid #fff;
        background: transparent;
        resize: none;
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
        color: rgba(34, 34, 96, 0.9);
        &::placeholder{
            color: rgba(34, 34, 96, 0.4);
        }
    }
    .input-control{
        input{
            width: 100%;
        }
    }

    .selects{
        display: flex;
        justify-content: flex-end;
        select{
            color: rgba(34, 34, 96, 0.4);
            &:focus, &:active{
                color: rgba(34, 34, 96, 1);
            }
        }
    }

    .submit-btn{
        button{
            box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
            &:hover{
                background: var(--color-green) !important;
            }
        }
    }
`;
export default MitarbeiterForm;