import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { x, user, calender, phone, clock, comment, tracking, email_icon } from '../../utils/Icons';
import Button from '../../Components/Button/Button';
import { motion } from "framer-motion";
import { FaTimes } from 'react-icons/fa';
import Swal from 'sweetalert2';

function MitarbeiterItem({
    type,
    id,
    vorname,
    nachname,
    rolle,
    phone_1,
    phone_2,
    email,
    passwort,
    updateMitarbeiter = { updateMitarbeiter },
    deleteMitarbeiter = { deleteMitarbeiter },
    indicatorColor
}) {

    const [showDetails, setShowDetails] = useState(false);
    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };


    const loggedInUserName = JSON.parse(localStorage.getItem('benutzer'))
    const isOwnProfile = `${vorname} ${nachname}` === loggedInUserName;

    return (
        <div>
            {showDetails ? (
                <DetailsCard
                    id={id}
                    vorname={vorname}
                    nachname={nachname}
                    rolle={rolle}
                    phone_1={phone_1}
                    phone_2={phone_2}
                    email={email}
                    passwort={passwort}
                    updateMitarbeiter={updateMitarbeiter}
                    deleteMitarbeiter={deleteMitarbeiter}
                    indicatorColor={indicatorColor}
                    isOwnProfile={isOwnProfile}
                    closeDetails={toggleDetails} />
            ) : (
                <SmallCard
                    id={id}
                    vorname={vorname}
                    nachname={nachname}
                    rolle={rolle}
                    phone_1={phone_1}
                    phone_2={phone_2}
                    email={email}
                    passwort={passwort}
                    indicatorColor={indicatorColor}
                    openDetails={toggleDetails} />
            )}
        </div>
    );
}

function SmallCard({
    id,
    vorname,
    nachname,
    rolle,
    phone_1,
    phone_2,
    email,
    passwort,
    indicatorColor,
    openDetails
}) {
    return (
        <motion.div
            className="small-card"
            onClick={openDetails}
            initial={{ opacity: 0, scale: 0.9, y: 0 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.9, y: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
        >
            <SmallCardStyled indicator={indicatorColor}>
                <div className="content">
                    <h5>{vorname} {nachname}</h5>
                    <div className="inner-content">
                        <div className="text">
                            <p>{phone} {phone_1}</p>
                            <p>{email_icon} {email}</p>
                        </div>
                    </div>
                </div>
            </SmallCardStyled>
        </motion.div>
    )
}

function DetailsCard({
    id,
    vorname: initialVorname,
    nachname: initialNachname,
    rolle: initialRolle,
    phone_1: initialPhone_1,
    phone_2: initialPhone_2,
    email: initialEmail,
    passwort: initialPasswort,
    updateMitarbeiter,
    deleteMitarbeiter,
    indicatorColor,
    closeDetails,
    isOwnProfile
}) {
    const [fields, setFields] = useState({
        vorname: initialVorname,
        nachname: initialNachname,
        rolle: initialRolle,
        phone_1: initialPhone_1,
        phone_2: initialPhone_2,
        email: initialEmail,
        passwort: initialPasswort,
        indicatorColor
    });

    const [initialFields, setInitialFields] = useState({
        vorname: initialVorname,
        nachname: initialNachname,
        rolle: initialRolle,
        phone_1: initialPhone_1,
        phone_2: initialPhone_2,
        email: initialEmail,
        passwort: initialPasswort,
        indicatorColor
    });

    const [changed, setChanged] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;
    
        // Entferne nicht-numerische Zeichen für phone_1 und phone_2
        if (name === 'phone_1' || name === 'phone_2') {
            updatedValue = value.replace(/\D/g, ''); // Entferne nicht-numerische Zeichen
        } else if (name === 'email') {
            // Überprüfe E-Mail-Format
            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
            if (!isValidEmail) {
                console.log("Invalid email address!");
                // Hier kannst du eine Fehlermeldung anzeigen oder eine andere Aktion durchführen
                return; // Verhindere, dass die ungültige E-Mail-Adresse gespeichert wird
            }
        }
    
        // Überprüfe, ob phone_1 leer ist und nur Zahlen enthält
        const isPhone1Empty = name === 'phone_1' && updatedValue === '';
        const isPhone2Empty = name === 'phone_2' && updatedValue === '';
    
        const updatedFields = {
            ...fields,
            [name]: updatedValue
        };
    
        // Überprüfe, ob alle Felder gleich ihren initialen Werten sind
        const allFieldsMatchInitialValues = Object.keys(updatedFields).every(fieldName => {
            return updatedFields[fieldName] === initialFields[fieldName];
        });
    
        setChanged(!allFieldsMatchInitialValues);
        setFields(updatedFields);
    
        // Zeige eine Fehlermeldung, wenn phone_1 leer ist, aber phone_2 nicht nur Zahlen enthält
        if (isPhone1Empty && !(/^\d+$/.test(updatedValue))) {
            console.log("Phone_1 field cannot be empty!");
            // Hier kannst du eine Fehlermeldung anzeigen oder eine andere Aktion durchführen
        }
    };
    


    useEffect(() => {
        console.log("vorname", initialVorname);
        console.log("neuer vorname", fields.vorname);
        console.log("changed", changed);
        console.log("changed", fields);
    });

    const handleUpdate = () => {
        updateMitarbeiter(id, fields);
    };

    const handleDeleteMitarbeiter = () => {
        Swal.fire({
          title: "Sicher, dass du den Mitarbeiter endgültig löschen möchtest?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Nein",
          confirmButtonText: "Ja, Mitarbeiter löschen!",
          background: "#fcf6f9",
          color: "#222260",
        }).then((result) => {
          if (result.isConfirmed) {
            deleteMitarbeiter(id)
            closeDetails()
            Swal.fire({
              title: "Gelöscht!",
              text: "Der Mitarbeiter wurde erfolgreich gelöscht",
              icon: "success",
              background: "#fcf6f9",
              color: "#222260",
              showConfirmButton: false,
              timer: 1200,
            });
          }
        });
      }

      const handleChangeMitarbeiterDetails = () => {
        Swal.fire({
          title: "Sicher, dass du die Änderungen speichern möchtest?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Nein",
          confirmButtonText: "Ja, Änderungen speichern!",
          background: "#fcf6f9",
          color: "#222260",
        }).then((result) => {
          if (result.isConfirmed) {
            handleUpdate()
            closeDetails()
            Swal.fire({
              title: "Änderungen gespeichert!",
              icon: "success",
              background: "#fcf6f9",
              color: "#222260",
              showConfirmButton: false,
              timer: 1200,
            });
          }
        });
      }

    return (
        <motion.div
            className="details-card"
            initial={{ opacity: 0, scale: 0.9, y: -50 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.9, y: -50 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
        >
            <BigCardStyled>
                <div className="close-icon" onClick={closeDetails}>
                    <FaTimes size={24} color="#000" />
                </div>
                <div className="content">
                    <div className="inner-content">
                        <div className="text-container">
                            <div className="input-control">
                                <input
                                    type="text"
                                    value={fields.vorname}
                                    onChange={handleChange}
                                    name="vorname"
                                    placeholder="Vorname"
                                    disabled
                                />
                            </div>
                            <div className="input-control">
                                <input
                                    type="text"
                                    value={fields.nachname}
                                    onChange={handleChange}
                                    name="nachname"
                                    placeholder="Nachname"
                                    disabled
                                />
                            </div>
                            <div className="selects input-control">
                                <select required value={fields.rolle} name="rolle" style={{ width: '100%' }} id="rolle" onChange={handleChange}>
                                    <option value="Admin">Admin</option>
                                    <option value="Verkäufer">Verkäufer</option>
                                    <option value="Werber">Werber</option>
                                    <option value="Bestätiger">Bestätiger</option>
                                </select>
                            </div>
                            <div className="input-control">
                                <input
                                    type="text"
                                    value={fields.phone_1}
                                    onChange={handleChange}
                                    name="phone_1"
                                    placeholder="phone_1"
                                />
                            </div>
                            <div className="input-control">
                                <input
                                    type="text"
                                    value={fields.phone_2}
                                    onChange={handleChange}
                                    name="phone_2"
                                    placeholder="phone_2"
                                />
                            </div>
                            <div className="input-control">
                                <input
                                    type="text"
                                    value={fields.email}
                                    onChange={handleChange}
                                    name="email"
                                    placeholder="email"
                                />
                            </div>
                            <div className="input-control">
                                <input
                                    type="text"
                                    value={fields.passwort}
                                    onChange={handleChange}
                                    name="passwort"
                                    placeholder="passwort"
                                />
                            </div>
                            {/* Hier weitere Eingabefelder hinzufügen */}
                        </div>
                    </div>
                </div>
                {changed ?
                    <ButtonStyled>
                        <Button
                            className="save-and-submit-btn"
                            name={'Änderungen speichern'}
                            bPad={'.5rem .8rem'}
                            bRad={'30px'}
                            bg={'var(--color-accent'}
                            color={'#fff'}
                            onClick={handleChangeMitarbeiterDetails}
                        />
                    </ButtonStyled>
                    : null
                }
                {isOwnProfile ? null : (
                    <Button
                        className="delete-employee-btn"
                        name={'Mitarbeiter entfernen'}
                        bPad={'.5rem .8rem'}
                        bRad={'30px'}
                        bg={'var(--color-accent'}
                        color={'#fff'}
                        onClick={handleDeleteMitarbeiter}
                    />
                )}
            </BigCardStyled>
        </motion.div>
    );
}

const SmallCardStyled = styled.div`
    flex-grow: 1; 
    background: #FCF6F9;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    color: #222260;
    .icon{
        width: 80px;
        height: 80px;
        border-radius: 20px;
        background: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #FFFFFF;
        i{
            font-size: 2.6rem;
        }
    }

    .content{
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: .2rem;
        h5{
            font-size: 1.3rem;
            padding-left: 2rem;
            position: relative;
            // hier könnte badge gerendert werden
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: .8rem;
                height: .8rem;
                border-radius: 50%;
                background: ${props => props.indicator};
            }
        }


        .inner-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .text{
                display: flex;
                align-items: center;
                gap: 1.5rem;
                p{
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    color: var(--primary-color);
                    opacity: 0.8;
                }
            }
        }
    }

    /* Hover-Verhalten für SmallCard beibehalten, außer wenn DetailsCard geöffnet ist */
    &:not(.details-card-open):hover {
        background-color: rgba(252, 252, 252, 0.9);
        cursor: pointer;
    }
`;


const BigCardStyled = styled.div`
    background: #FCF6F9;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    color: #222260;
    justify-content: space-between;
    z-index: 9;
    flex-direction: column;
    .text-container .input-control {
        margin: 1rem;
    }
    
    .close-icon {
        cursor: pointer;
    }
    
    .close-icon:hover svg {
        transform: scale(1.2); /* Make it larger on hover */
        transition: transform 0.2s ease; /* Add smooth transition */
        fill: red; /* Change color to red on hover */
    }
`;

const ButtonStyled = styled.div`
.export-btn{
    margin-right: 0.5rem;
    margin-top: 0.5rem;
button{
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    &:hover{
        background: var(--color-green) !important;
    }
}
}
`;

export default MitarbeiterItem