import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { InnerLayout } from '../../styles/Layouts';
import { useGlobalContext } from '../../context/globalContext';
import TerminItem from '../Werber/TerminItem';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from '../../Components/Button/Button';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export default function Termine() {
    const [value, setValue] = useState(0);
    const { getAllTerminstaten, terminstatenList, getAllTermine, AllTermine, getAllActiveVerkaeufer, verkaeuferList, storniereTermin, updateTermin } = useGlobalContext();
    const angemeldeterWerber = JSON.parse(localStorage.getItem('benutzer'));

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const [searchStrasse, setSearchStrasse] = useState('');
    const [startDate, setStartDate] = useState(getTodayDate());
    const [endDate, setEndDate] = useState(getTodayDate());
    const [selectedTerminstatus, setSelectedTerminstatus] = useState('');
    const [visit_startDate, setvisitStartDate] = useState('');
    const [visit_endDate, setvisitEndDate] = useState('');

    const handleTerminstatusChange = (event) => {
      setSelectedTerminstatus(event.target.value);
    };

    const [selectedFilters, setSelectedFilters] = useState([]);
    let filters = ["Termin", "Wiedervorlage", "Nicht da", "Keine Interesse"];

    const handleFilterButtonClick = (selectedCategory) => {
      if (selectedFilters.includes(selectedCategory)) {
        setSelectedFilters(prevFilters => prevFilters.filter(el => el !== selectedCategory));
      } else {
        // Überprüfen, ob ein anderer Filter bereits ausgewählt ist
        const otherSelectedFilters = selectedFilters.filter(filter => filter !== "alle Kategorien");
        if (otherSelectedFilters.length > 0) {
          setSelectedFilters(prevFilters => [...prevFilters, selectedCategory]);
        } else {
          setSelectedFilters([selectedCategory]);
        }
      }
    };

    const [activeVerkaeufer, setActiveVerkaeufer] = useState([]);

    useEffect(() => {
      getAllTerminstaten();
      getAllActiveVerkaeufer();
      getAllTermine(startDate, endDate);
      console.log("selectedFilters", selectedFilters);
    }, [selectedFilters, startDate, endDate]);

    const [filteredTermine, setFilteredTermine] = useState([]);

    useEffect(() => {
      setFilteredTermine(
        AllTermine
          .filter((termin) => termin.werber === angemeldeterWerber)
          .filter((termin) => {
            const searchLowerCase = searchStrasse.toLowerCase();
            return searchLowerCase === '' || termin.strasse.toLowerCase().includes(searchLowerCase);
          })
          .filter((termin) => {
            // Hier das Datum um einen Tag erhöhen und vergleichen
            const dayAfter = new Date(termin.datum);
            dayAfter.setDate(dayAfter.getDate() + 1);
            return startDate === '' || dayAfter > new Date(startDate);
          })
          .filter((termin) => {
            // Hier das Datum um einen Tag reduzieren und vergleichen
            const dayBefore = new Date(termin.datum);
            dayBefore.setDate(dayBefore.getDate() - 1);
            return endDate === '' || dayBefore < new Date(endDate);
          })
          .filter((termin) => {
            // Hier das Datum um einen Tag erhöhen und vergleichen
            const visit_dayAfter = new Date(termin.createdAt);
            visit_dayAfter.setDate(visit_dayAfter.getDate());
            return visit_startDate === '' || visit_dayAfter > new Date(visit_startDate);
          })
          .filter((termin) => {
            // Hier das Datum um einen Tag reduzieren und vergleichen
            const visit_dayBefore = new Date(termin.createdAt);
            visit_dayBefore.setDate(visit_dayBefore.getDate() - 1);
            return visit_endDate === '' || visit_dayBefore < new Date(visit_endDate);
          })
          .filter((termin) => {
            const searchLowerCase = selectedTerminstatus.toLowerCase();
            return searchLowerCase === '' || termin.terminstatus.toLowerCase().includes(searchLowerCase);
          })
          .filter((termin) => {
            const searchLowerCase = selectedFilters.map(filter => filter.toLowerCase());
            return searchLowerCase.length === 0 || searchLowerCase.includes(termin.status.toLowerCase());
          })
      );
    }, [AllTermine, angemeldeterWerber, searchStrasse, startDate, endDate, visit_startDate, visit_endDate, selectedTerminstatus, selectedFilters]);
    
    // State für das Ausklappen des Headers
    const [isHeaderExpanded, setIsHeaderExpanded] = useState(false);

    const disableDropdownCondition = visit_startDate === '' || visit_endDate === '';
    return (
      <TermineStyled>
          <InnerLayout>
          <Box sx={{ width: '100%'}}>
            <div className={`sticky-header ${isHeaderExpanded ? 'expanded' : ''}`}>
                <div className="header-icon" onClick={() => setIsHeaderExpanded(!isHeaderExpanded)}>
                {isHeaderExpanded ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                <h1 style={{ textAlign: 'center' }}>Terminübersicht</h1>
                <div className="search-inputs">
                <Form>
                    <InputGroup className='input-control'>
                        <Form.Control
                            onChange={(e) => setSearchStrasse(e.target.value)}
                            placeholder='Suche Strasse'
                        />
                    </InputGroup>
                </Form>
                <Form>
                <InputGroup className='input-control'>
                        <Form.Control
                            type="date"
                            value={startDate}
                            onChange={(e) => {
                                if (e.target.value) { // Überprüfung, ob ein Datum ausgewählt wurde
                                    const selectedDate = new Date(e.target.value);
                                    selectedDate.setDate(selectedDate.getDate()); // Einen Tag abziehen
                                    const formattedDate = selectedDate.toISOString().split('T')[0]; // Formatierung in "YYYY-MM-DD"
                                    setStartDate(formattedDate);
                                } else {
                                    setStartDate(''); // Wenn kein Datum ausgewählt ist, leeren String setzen
                                }
                            }}
                            disabled={disableDropdownCondition}
                            placeholder='Startdatum eingeben'
                        />
                    </InputGroup>
                </Form>
                <Form>
                    <InputGroup className='input-control'>
                        <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(e) => {
                              if (e.target.value) {
                                  const selectedDate = new Date(e.target.value);
                                  selectedDate.setDate(selectedDate.getDate()); // Einen Tag hinzufügen
                                  const formattedDate = selectedDate.toISOString().split('T')[0];
                                  setEndDate(formattedDate);
                              } else {
                                  setEndDate('');
                              }
                            }}
                            disabled={disableDropdownCondition}
                            placeholder='Enddatum eingeben'
                        />
                    </InputGroup>
                </Form>
                <Form>
                    <InputGroup className='selects input-control'>
                    <select id="terminstatusDropdown" onChange={handleTerminstatusChange} value={selectedTerminstatus || ''}>
                                <option value="">Alle Terminstaten</option>
                                {terminstatenList.map((terminstatus, index) => (
                                    <option key={index} value={terminstatus}>
                                        {terminstatus}
                                    </option>
                                ))}
                            </select>
                    </InputGroup>
                </Form>
                <Form>
        <InputGroup className='input-control'>
            <Form.Control
                type="date"
                value={visit_startDate}
                onChange={(e) => {
                    if (e.target.value) { // Überprüfung, ob ein Datum ausgewählt wurde
                        const selectedDate = new Date(e.target.value);
                        selectedDate.setDate(selectedDate.getDate()); // Einen Tag abziehen
                        const formattedDate = selectedDate.toISOString().split('T')[0]; // Formatierung in "YYYY-MM-DD"
                        setvisitStartDate(formattedDate);
                    } else {
                        setvisitStartDate(''); // Wenn kein Datum ausgewählt ist, leeren String setzen
                    }
                }}
                placeholder='Startdatum eingeben'
            />
        </InputGroup>
        </Form>
        <Form>
        <InputGroup className='input-control'>
                <Form.Control
                    type="date"
                    value={visit_endDate}
                    onChange={(e) => {
                      if (e.target.value) {
                          const selectedDate = new Date(e.target.value);
                          selectedDate.setDate(selectedDate.getDate()); // Einen Tag hinzufügen
                          const formattedDate = selectedDate.toISOString().split('T')[0];
                          setvisitEndDate(formattedDate);
                      } else {
                          setvisitEndDate('');
                      }
                    }}
                    placeholder='Enddatum eingeben'
                />
            </InputGroup>
        </Form>
                </div>
                <div className="buttons-container">
                    {filters.map((category, idx) => (
                      <button
                        onClick={() => handleFilterButtonClick(category)}
                        className={`button ${
                          selectedFilters?.includes(category) ? "active" : ""
                        }`}
                        key={`filters-${idx}`}
                      >
                        {category}
                      </button>
                    ))}
                  </div>
              </div>
              <div className='anzahl-header'>
                <h3>Anzahl: {filteredTermine.length}</h3>
              </div>
              <div className="income-content">
                <div className="incomes">
                  {filteredTermine.map((termin) => {
                    const {_id, adresse, strasse, hausnummer, plz, ort, vorname, nachname, email, phone_1, phone_2, werber,  datum, createdAt, uhrzeit, status, kommentar, terminstatus, jahresverbrauch, personen_count, mtl_abschlag, art_flaeche, dacheindeckung, ausrichtung_flaeche, terminbestaetigung, zugewiesener_verkaeufer, status_verkaeufer} = termin;
                    return <TerminItem
                      key={_id}
                      id={_id} 
                      adresse={adresse}
                      strasse={strasse}
                      hausnummer={hausnummer}
                      plz={plz}
                      ort={ort}
                      vorname={vorname}
                      nachname={nachname}
                      email={email}
                      phone_1={phone_1}
                      phone_2={phone_2} 
                      datum={datum}
                      createdAt={createdAt} 
                      uhrzeit={uhrzeit} 
                      status={status}
                      kommentar={kommentar} 
                      terminstatus={terminstatus}
                      jahresverbrauch={jahresverbrauch}
                      personen_count={personen_count}
                      mtl_abschlag={mtl_abschlag}
                      art_flaeche={art_flaeche}
                      dacheindeckung={dacheindeckung}
                      ausrichtung_flaeche={ausrichtung_flaeche}
                      terminbestaetigung={terminbestaetigung}
                      zugewiesener_verkaeufer={zugewiesener_verkaeufer}
                      status_verkaeufer={status_verkaeufer}
                      werber={werber}
                      verkaeuferList={verkaeuferList}
                      indicatorColor="var(--color-green)"
                      storniereTermin={storniereTermin}
                      updateTermin={updateTermin}
                    />
                  })}
                </div>
              </div>
          </Box>
          </InnerLayout>
      </TermineStyled>
    );
  }
  
  const TermineStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: auto;
  input, textarea, select{
    font-family: inherit;
    font-size: inherit;
    outline: none;
    border: none;
    padding: .5rem 1rem;
    border-radius: 5px;
    border: 2px solid #fff;
    background: transparent;
    resize: none;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
    color: rgba(34, 34, 96, 0.9);
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
    &::placeholder{
        color: rgba(34, 34, 96, 0.4);
    }
}
.input-control{
    input{
        width: 100%;
    }
}

.selects{
    display: flex;
    justify-content: flex-end;
    select{
        color: rgba(34, 34, 96, 0.4);
        &:focus, &:active{
            color: rgba(34, 34, 96, 1);
        }
    }
}

    .income-content {
        margin-top: 20px;
        display: flex;
        gap: 2rem;

        max-height: calc(100vh - 45vh);
        overflow-y: auto;
        @media (max-width: 768px) {
          max-height: calc(100vh - 20vh);
          margin-top: 15px;
        }

        .incomes {
            flex: 1;
            @media (max-width: 768px) {
              width: 100%;
            }
        }
    }

    .such-btn {
      margin-top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      
      button {
        box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.06);
      
        &:hover {
          background: var(--color-green) !important;
        }
      }
    }


    /* Stile für die Suchfelder */
    .search-inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;

        .my-3 {
            flex: 1; /* Die Suchfelder sollen gleichmäßig wachsen */
        }

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    .buttons-container {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
    
    .button {
      border: 2px solid white;
      background: transparent;
      margin: 5px;
      border-radius: 10px;
      padding: 10px 20px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      font-size: 16px;
    }
    
    .active {
      background-color: var(--color-accent) !important;
      border: 2px solid var(--color-accent);
      color: #fff;
    }

    .sticky-header {
      position: sticky;
      top: 2rem;
      z-index: 999999;
      padding: 10px;
      transition: max-height 0.3s ease-in-out;

      @media (max-width: 768px) {
        top: 0;
        max-height: 50px;
        overflow: hidden;

        &.expanded {
          max-height: 700px; /* Höhere Höhe für ausgeklappten Zustand */
        }
      }
    }

    .header-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 10px;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .anzahl-header {
      margin: 1rem;
    }
`;
